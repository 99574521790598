<template>
  <SyllabusDialog @close="closeSyllabusModal" :modalSyllabusActive="modalSyllabusActive"
                  v-model:exam="selectedExam" @startExam="onExamStartClicked" :isStartBtn="isShowStartBtn">

  </SyllabusDialog>
  <carousel
      v-bind="settings" :breakpoints="breakpoints"
  >
    <slide
        v-for="(item, index) in routineList"
        :key="index"
    >
      <CourseExamDetails
          :item="item"
          :isLive="true"
          :hideBtn="true"
          :isHome="true"
          @syllabusModal="syllabusModal"
          @startExamDialog="startExamDialog"
      >
      </CourseExamDetails>
    </slide>
    <template #addons>
      <Pagination />
    </template>
  </carousel>

<!--  <div class="all-exam-web">-->
<!--    <div class="row mt-3">-->
<!--      <div class=" col-lg-12 col-md-12 col-sm-12">-->
<!--        <a class="live-exam-all" href="" @click="handleAllButtonClick">-->
<!--          সবগুলো দেখুন-->
<!--          <svg-->
<!--            class="archived-button-arrow"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            viewBox="0 0 24 24"-->
<!--            fill="none"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            style="top: 4px;"-->
<!--        >-->
<!--          <path-->
<!--              d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z"-->
<!--              fill="#0095FF"-->
<!--          />-->
<!--        </svg>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>
  
<script>
import 'vue3-carousel/dist/carousel.css';
import httpClient from "@/config/httpClient";
import { defineComponent, ref, onMounted } from "vue";
import {StaticTags, URLs} from "@/static";
import { useRouter } from "vue-router";
import CourseExamDetails from "@/components/CourseExamDetails.vue";
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import store from '@/store/index.js';
import SyllabusDialog from "@/components/ExamList/SyllabusDialog.vue";
import {setExamInfo, setExamParams} from "@/config/utils";

export default defineComponent({

  props: ['isLiveMode'],
  components: {
    CourseExamDetails,
    SyllabusDialog,
    Carousel,
    Slide,
    Pagination
  },
  setup() {

    const routineList = ref([])
    const modalMenuActive = ref(false);
    const modalSyllabusActive = ref(false)
    const selectedExam = ref(null)
    const isShowStartBtn = ref(false)
    const isRunningExam = ref(false)
    const router = useRouter();
    const totalLabel = ref("All Routine / Archieved")

    onMounted(() => {
      getLiveExamList()

    });

    const handleAllButtonClick = () => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_NAME,
      })
    };
    const closeSyllabusModal = () => {
      modalSyllabusActive.value = false
    }

    const syllabusModal = (exam) => {
      isShowStartBtn.value = false
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
    }

    const startExamDialog = (exam) => {
      isShowStartBtn.value = true
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam

      if (exam.ended == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
      }
    }

    const onExamStartClicked = () => {
      setExamParams({
        examId: selectedExam.value.id
      })
      setExamInfo({
        id: selectedExam.value.id,
        title: selectedExam.value.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM,
        isRunningExam: isRunningExam.value,
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }


    const getLiveExamList = async () => {
      const config = {
        params: {
          "running": 1,
          "upcoming": 0,
          "previous": 0,
          "own": 0
        },
      };

      const url = process.env.VUE_APP_API_BASE_URL + "/exams";

      await httpClient
        .get(url, config)
        .then((res) => {
          routineList.value = res.exams
        })
    }
    return {
      routineList,
      totalLabel,
      handleAllButtonClick,
      closeSyllabusModal,
      isShowStartBtn,
      modalSyllabusActive,
      selectedExam,
      isRunningExam,
      modalMenuActive,
      syllabusModal,
      startExamDialog,
      onExamStartClicked,
    };
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      draggable: true,
    },
    breakpoints: {
      768: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      769: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
  }),
});
</script>
<style >
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel__pagination-button::after {
  border-radius: 50%!important;
  width: 10px !important;
  height: 10px !important;
}

.carousel, .slide {
  width: 100%;
}


.live-exam-all {

  font-family: 'Noto Sans Bengali';
  font-size: 20px;
  font-weight: 650;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;


}

@media (max-width: 768px) {
  .all-exam-web {
    display: none;
  }
}


</style>
  