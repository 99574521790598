<template>
  <div class="">
    <b-button class="select-button" type="button">নিচের সকল বিষয়</b-button>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.select-button {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FA3434;
  color: #FFF;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
.button-text {
  color: #FFF;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */
}
.btn-secondary {
  border-color: #FA3434;
}

.btn-secondary:hover {
  background: rgba(250, 52, 52, 0.85);
  border-color: #FA3434;
}
</style>