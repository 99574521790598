<template>
  <!-- Navbar -->

  <nav class="navbar navbar-expand-md navbar-dark navbar-custom navbar-custom-dynamically">

    <!-- Image Logo -->
    <a class="navbar-brand " href="/">
      <img :src="require('@/assets/logo2.png')" alt="" width="50" />
    </a>

    <!-- Mobile Menu Toggle Button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-awesome fas fa-bars"></span>
      <span class="navbar-toggler-awesome fas fa-times"></span>
    </button>
    <!-- end of mobile menu toggle button -->

    <div v-if="isLoggedIn" class="collapse navbar-collapse" id="navbarsExampleDefault">
<!--      <form class="d-flex search-input-form">-->
<!--        <i class="fa-solid fa-magnifying-glass search-icon"></i>-->
<!--        <input-->
<!--            class="nav-form-control form-control input-form"-->
<!--            type="search"-->
<!--            placeholder="খুঁজুন"-->
<!--            aria-label="Search"-->
<!--        />-->
<!--      </form>-->

      <ul class="navbar-nav navbar-items-container" style="margin-left: auto">
        <li class="nav-item nav-list">
          <a class="nav-link link" href="#" @click="goToLiveExam">লাইভ এক্সাম </a>
        </li>

        <li class="nav-item nav-list dropdown-hover">
          <a class="nav-link link" href="#" @click="goToCategoryPage">
            <p>বিষয়ভিত্তিক পরীক্ষা</p>
            <i class="arrow down"></i
          ></a>
          <div class="dropdown-content">
            <div v-for="category in categoryList" :key="category.id">
              <a class="bg-transparent" @click="onCategorySelected(category)" style="cursor: pointer;">{{ category.name }}</a>
              <li><hr class="dropdown-divider"></li>
            </div>
          </div>
        </li>
        <li class="nav-item nav-list dropdown-hover">
          <a class="nav-link link" href="#">
            <p>অনুশীলন</p>
            <i class="arrow down"></i
            ></a>
          <div class="dropdown-content">
            <div class="personal-practise-list">
              <PracticeButtons :fromHeader="true" />
            </div>
          </div>
        </li>
        <li v-if="isLoggedIn && userDetails" class="nav-item nav-list">
          <a class="nav-link link text-white bg-transparent" href="#" @click="goToPackagePage">প্যাকেজ</a>
        </li>
        <li v-if="!isLoggedIn" class="nav-item nav-list">
          <a  class="nav-link page-scroll" href="/login">লগইন{{ "\xa0" }}/রেজিস্ট্রেশন</a>
        </li>
<!--        <li v-if="!isLoggedIn" class="nav-list button bg-blue signup-btn-width-responsive">-->
<!--          <a class="nav-link link text-white bg-transparent signup-btn" href="/signup" style="color:white !important"-->
<!--          >সাইন আপ</a-->
<!--          >-->
<!--        </li>-->
<!--        <li v-if="isLoggedIn && userDetails" class="nav-item nav-list">-->
<!--          <a class="nav-link link text-white bg-transparent" href="#" @click="goToResultPage">ফলাফল</a>-->
<!--        </li>-->

        <li v-if="isLoggedIn && userDetails" class="nav-item dropdown">
          <a class="nav-link navbar-dropdown-box dropdown-toggle page-scroll" href="#" id="navbarDropdown" role="button"
             aria-haspopup="true" aria-expanded="false"><i class='fa fa-user link-icon mr-1'> </i>{{ userDetails.name }}</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!--                        <a class="dropdown-item" href="#" ><span class="item-text"><i class='fa fa-user link-icon mr-1'> </i>প্রোফাইল</span></a>-->
            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><span class="item-text"><i class='fa fa-user link-icon mr-1'> </i>প্রোফাইল</span></a>
            <div class="dropdown-items-divide-hr"></div>
            <a class="dropdown-item" href="/purchase-history"><span class="item-text"><i class='fa fa-credit-card link-icon mr-1'> </i>ট্রানজেকশন
                                হিস্ট্রি</span></a>
            <div class="dropdown-items-divide-hr"></div>
            <a class="dropdown-item" href="" @click="logout"><span class="item-text"><i class="fa-solid fa-arrow-right-from-bracket mr-1"></i>লগআউট</span></a>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="isLoggedIn && userDetails" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">প্রোফাইল</h5>
          </div>
          <div class="modal-body">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="text-left" scope="col">{{ userDetails.name }}</th>
              </tr>
              <tr>
                <th class="text-left" scope="col">ID: {{ userDetails.id }} | {{ userDetails.userid }}</th>
              </tr>
              <tr v-if="userDetails.membership == 'ACTIVE'">
                <th class="text-left" scope="col"> একটিভ | মেয়াদ: {{ userDetails.end_date }}</th>
              </tr>
              <tr v-if="userDetails.membership != 'ACTIVE'">
                <th class="text-left" style="color: red" scope="col"> কোন একটিভ প্যাকেজ নাই </th>
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


<!--    <b-alert show>-->
<!--      Hello, World!-->
<!--    </b-alert>-->
  </nav>
  <!-- end of navbar
   end of navbar -->
</template>
<script>
import store from '@/store/index.js';
import { removeItem,setStore } from "@/config/utils";
import {StaticTags, URLs} from "@/static";
import { useRouter } from "vue-router";
// import Swal from 'sweetalert2/dist/sweetalert2';
import {  onMounted, ref} from "vue";
import httpClient from "@/config/httpClient";
import {mapState} from "vuex";
import PracticeButtons from "@/components/PracticeButton.vue";
export default {
  name: "NewHeaderView",
  components: {
    PracticeButtons
  },
  watch: {
    categoryList() {
      return store.categoryList
    },
  },
  computed: mapState(["categoryList"]),
  created: function () {
    this.$store.dispatch("loadCategoryList"); // load data
  },
  setup() {
    const router = useRouter();
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const userDetails = ref(null)
    const showPopup = ref(true)
    onMounted(()=>{
      if (isLoggedIn) {
        getUserDetails()
      }

    })
    const goToResultPage = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_RESULT)
      router.push("/results/");
    }
    const goToPackagePage = () => {
      store.dispatch("setTitleBar", "প্যাকেজ")
      router.push("/packages");
    }
    const goToLiveExam = () => {
      store.dispatch("setTitleBar", "লাইভ এক্সাম")
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_LIVE_COURSE_EXAM_LIST_NAME,
      })
    };

    const goToCategoryPage = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
      store.dispatch("setTitleBar", "বিষয়ভিত্তিক পরীক্ষা")
      router.push({
        name: URLs.ROUTE_EXAM_CATEGORIES_NAME
      });
    }
    const getUserDetails = async () => {
      await httpClient
          //.post(baseUrl + "api2/login/", loginParams)
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                userDetails.value = response.user
                setStore(StaticTags.USER_DETAILS, response.user)
              }

            }

          })
    }
    //const userDetails = JSON.parse(getStore(StaticTags.USER_DETAILS))
    //console.log(JSON.parse(userDetails));
    // if (userDetails == undefined || userDetails == null || userDetails == "null") {
    //   Swal.fire({
    //     text: "No User Found",
    //     title: "Failed",
    //     icon: StaticTags.ALERT_TYPE_ERROR,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Reload'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       router.go()
    //     }
    //   })
    //
    // }
    function logout() {
      removeItem(StaticTags.IS_LOGGED_IN)
      store.commit("setAcessToken", null)
      router.push("/login/")
    }
    const onCategorySelected = (category) => {
      store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
      router.push({
        name: URLs.ROUTE_EXAM_SUB_CATEGORIES_NAME,
        params: {
          cid: category.id,

        }
      })
      // goToSubCategories(category)
    }

    // const goToSubCategories = (category) => {
    //   router.push({
    //     name: URLs.ROUTE_EXERCISE_SUB_CATEGORIES_NAME,
    //     params: {
    //       cid: category.id,
    //
    //     }
    //   })
    // }

    return {
      logout,
      isLoggedIn,
      userDetails,
      goToResultPage,
      goToCategoryPage,
      onCategorySelected,
      goToLiveExam,
      goToPackagePage,
      showPopup,
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap");
:root {
  --blue: #0095ff;
  --white: #ffffff;
  --off-white: #f5f5f5;
}
.my-nav-item {
  color: rgba(55, 83, 107, 0.99) !important;
  font-weight: 500;
  padding-right: 10%;
  font-family: "Noto Sans Bengali" !important;
  font-size: 15px;
}

.bg-light {
  background-color: white !important;
}

.hidden {
  visibility: hidden;
}

.base-color-button {
  background-color: #FA3434 !important;
  width: 100%;
}

/**************************/
/*     Navigation     */
/**************************/

.signup-btn {
  color: white !important;
  width: 80px;
  height: 27px;
  border-radius: 12px;
  background: #0095FF;
}

.dropdown-indicator {
  background-color: #fff;
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  transform: rotate(45deg);
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px 3px;
  position: absolute;
  bottom: 34px;
  right: 58px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.button {
  padding: 2px 20px !important;
  border-radius: 12px;
  border: 0;
  color: var(--white);
}
.bg-blue {
  background-color: var(--blue);
}

.dropdown-hover {
  position: relative;
}
.dropdown-hover > .dropdown-content {
  position: absolute;
  width: 275px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  background-color: var(--off-white);
  border: 0.1px solid lightgray;
  box-shadow: 0px -8px 15px lightgray;
  z-index: 1;
  text-align: start;
}
.dropdown-hover:hover > .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: 0.8s;
  padding: 20px;
  top: 40px;
  left: 0;
}
.dropdown-hover:hover > .dropdown-content a {
  display: block;
  padding: 10px;
  color: rgb(128, 128, 128);
  text-decoration: none;
}

.search-input-form {
  width: 23%;
  position: relative;
  margin-left: 50px;
}
.nav-form-control {

}
.nav-form-control:focus {
  //color: #495057;
  //background-color: #fff;
  //border-color: #fffbfa;
  //outline: 0;
  //box-shadow: 0 0 0 0.1px #fffbfa !important;
}
.search-input-form .input-form {
  border-radius: 48px;
  padding: 8px 40px;
}
.search-icon {
  position: absolute;
  top: 20px;
  left: 17px;
  color: rgb(128, 128, 128);
}

.navbar-custom-dynamically {
  background: #fffbfa;
  padding: 0rem 2.5rem !important;
  color: black;
  border-bottom: 1px solid #1714151A;
  border-radius: 5px;
}

.navbar-custom {

  //background-color: #113448;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  font: 600 0.875rem/0.875rem "Noto Sans Bengali", sans-serif;
  transition: all 0.2s;
  padding: 1rem 2.5rem;
}

.navbar-custom .navbar-brand {
  font-size: 0;
}


.navbar-custom .navbar-brand.logo-image img {
  width: 7.0625rem;
  height: 2rem;
  -webkit-backface-visibility: hidden;
}

.navbar-dropdown-box {
  border: 1px solid black;
  border-radius: 6px;
  box-shadow: 0 1px 3px #2a97ef, 0 1px 3px #2a97ef;
}

.navbar-custom .navbar-brand.logo-text {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font: 700 2.25rem/1.5rem "Noto Sans Bengali", sans-serif;
  color: black;
  text-decoration: none;
}

.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-brand img {
  width: 80px !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #0a0a0a !important;
}


.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
  color: #14bf98;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(55, 83, 107, 0.99);
  font-weight: 500;
  padding-right: 10%;
  font-family: "Noto Sans Bengali" !important;
  font-size: 15px;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover>.dropdown-menu {
  display: block;
  /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s;
  /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-custom .dropdown-toggle:focus {
  /* removes dropdown outline on focus  */
  outline: 0;
}

.navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: white;
}

.navbar-custom .dropdown-item {
  color: rgba(55, 83, 107, 0.99) !important;
  text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
  background-color: #0095FF;
}

.navbar-custom .dropdown-item .item-text {
  font: 600 0.875rem/0.875rem "Noto Sans Bengali", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
  color: white;
}

.navbar-custom .dropdown-items-divide-hr {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.75rem auto;
  border: none;
  background-color: #b5bcc4;
  opacity: 0.2;
}


/* end of dropdown menu */

.personal-practise-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.navbar-custom .social-icons {
  display: none;
}

.navbar-custom .navbar-toggler {
  border: none;
  color: #2a97ef;
  font-size: 2rem;
}

.navbar-dark .navbar-toggler {
  color: #FA3434 !important;
  border-color: #FA3434 !important;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times {
  display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}

@media (min-width: 1600px) {

  /* Navigation */
  .navbar-custom {
    padding-right: 12rem;
    padding-left: 12rem;
  }

  .navbar-custom.top-nav-collapse {
    padding-right: 12rem;
    padding-left: 12rem;
  }

  /* end of navigation */
}

@media (min-width: 1200px) {
  .navbar-custom {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .navbar-custom.top-nav-collapse {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

@media (min-width: 768px) {
  .navbar-custom-dynamically {
    background: #fffbfa !important;
    padding: 1rem 2.5rem !important;
    color: black;
  }

  .navbar-custom {
    padding: 2.125rem 1.5rem 2.125rem 2rem;
    box-shadow: none;
    background: transparent;
  }

  .navbar-custom .navbar-brand.logo-text {
    color: rgba(55, 83, 107, 0.99) !important;
  }

  .navbar-custom .navbar-nav {
    margin-top: 0.75rem !important;
    margin-bottom: 0.5rem !important;

  }

  .navbar-custom .nav-item .nav-link {
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  }

  .navbar-custom .nav-item .nav-link:hover,
  .navbar-custom .nav-item .nav-link.active {
    color: #14bf98;
  }

  .navbar-custom.top-nav-collapse {
    padding: 0.375rem 1.5rem 0.375rem 2rem;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    background-color: #113448;
  }

  .navbar-custom.top-nav-collapse .navbar-brand.logo-text {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    color: #14bf98;
  }

  .navbar-custom .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.03);
  }

  .navbar-custom.top-nav-collapse .dropdown-menu {
    border-top: 0.125rem solid rgba(0, 0, 0, 0);
  }

  .navbar-custom .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar-custom .dropdown-items-divide-hr {
    width: 84%;
  }

  .navbar-collapse {
    align-items: start !important;
  }
}
</style>
