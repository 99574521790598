<template>
  <Header/>
  <SubMenu :title="title"></SubMenu>
  <div class="container-fluid" :key="refreshComponent">
    <div class="container-fluid pl-0 pr-0">
      <MenuDialog @close="closeMenuModal" :coordinates="coordinates" :modalMenuActive="modalMenuActive"
                  v-model:exam="selectedExam">

      </MenuDialog>

      <SyllabusDialog @close="closeSyllabusModal" :modalSyllabusActive="modalSyllabusActive"
                      v-model:exam="selectedExam" @startExam="onStartExamClicked" :isStartBtn="isShowStartBtn">

      </SyllabusDialog>

      <div class="" style="margin-top: 10px;">

        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="true" @click="getRoutineList">রুটিন
            </button>
            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false"
                    @click="getArcheivedList">আর্কাইভ
            </button>
            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false" v-if="true"
                    @click="getResultList">ফলাফল

            </button>
          </div>
        </nav>
      </div>
      <transition-group name="">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div v-if="isRoutine && Object.keys(routineList).length > 0">
              <marquee width="60%" direction="left" height="30%" style="color: red">★ Running লাইভ পরীক্ষা পরীক্ষার দিন
                সকাল ৬ টা থেকে রাত ২ টা পর্যন্ত
                দেয়া যাবে যে কোনো সময়।
              </marquee>
              <div v-for="(items, key) in routineList" :key="key">
                <div class="exam-list-container">
                  <div class="container-child-1">
                    <h2 class="date-format">{{ convertToBanglaDate(key) }}</h2>
                  </div>
                  <div class="container-child-2">
                    <CourseExamDetails v-for="(item, index) in items" :key="index" :item="item"
                                       :isSingleCourse="isSingleCourse"
                                       :isLive="isLive" :status="getStatus(item)" :isMenuDisabled="isMenuDisabled"
                                       @showMenuModal="showMenuModal" @showSyllabusModal="showSyllabusModal"
                                       @showStartExamDialog="showStartExamDialog">
                    </CourseExamDetails>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div v-if="isArcheived && Object.keys(archievedList).length > 0">
              <h5 class="p-4"></h5>
              <div v-for="( items, key ) in archievedList" :key="key">
                <div class="exam-list-container">
                  <div class="container-child-1">
                    <h2 class="date-format">{{ convertToBanglaDate(key) }}</h2>
                  </div>
                  <div class="container-child-2">
                    <CourseExamDetails v-for="(item, index) in items" :key="index" :item="item"
                                       :isSingleCourse="isSingleCourse"
                                       :status="getStatus(item)" :isResult="isResult" :isLive="isLive"
                                       :isMenuDisabled="isMenuDisabled" @showMenuModal="showMenuModal"
                                       @showSyllabusModal="showSyllabusModal"
                                       @showStartExamDialog="showStartExamDialog">
                    </CourseExamDetails>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <div v-if="isResult && Object.keys(resultList).length > 0">
              <div v-for="( items, key ) in resultList" :key="key">
                <div class="exam-list-container">
                  <div class="container-child-1">
                    <h2 class="date-format">{{ convertToBanglaDate(key) }}</h2>
                  </div>
                  <div class="container-child-2">
                    <CourseExamDetails v-for="(item, index) in items" :key="index" :item="item"
                                       :isSingleCourse="isSingleCourse"
                                       :status="getStatus(item)" :isResult="isResult" :isLive="isLive"
                                       :isMenuDisabled="isMenuDisabled" @showMenuModal="showMenuModal"
                                       @showSyllabusModal="showSyllabusModal"
                                       @showStartExamDialog="showStartExamDialog">
                    </CourseExamDetails>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(isRoutine && Object.keys(routineList).length == 0 ) ||
                            (isArcheived && Object.keys(archievedList).length == 0) ||
                            (isResult && Object.keys(resultList).length == 0)
                            " class="no-exam-text">
          <h4>কোনো পরীক্ষা পাওয়া যায়নি !</h4>
        </div>

      </transition-group>
    </div>

    <div ref="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import CourseExamDetails from "@/components/CourseExamDetails.vue";
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import httpClient from "@/config/httpClient";
import {useRoute, useRouter} from "vue-router";
import store from '@/store/index.js';
import {StaticTags, URLs} from "@/static";
import MenuDialog from "@/components/ExamList/MenuDialog.vue";
import SyllabusDialog from "@/components/ExamList/SyllabusDialog.vue";
import Header from "@/components/NewHeaderView.vue";
import Swal from 'sweetalert2/dist/sweetalert2';
import {setExamInfo, setExamParams} from "@/config/utils";
import SubMenu from "@/components/SubMenu.vue";
import Footer from "@/components/FooterView.vue";
// import LectureAndNotes from "@/components/LectureAndNotes.vue";
import {convertToBanglaDate} from "@/config/utils";

export default defineComponent({

  components: {
    // LectureAndNotes,
    Footer,
    SubMenu,
    CourseExamDetails, MenuDialog, SyllabusDialog, Header
  },
  setup() {
    const router = useRouter();
    const refreshComponent = ref(0);
    // const flowType = store.state.flowType

    const route = useRoute();
    const routineList = ref([])
    const course = ref([])
    const archievedList = ref([])
    const resultList = ref([])
    const isResult = ref(false)
    const isRoutine = ref(true)
    const isArcheived = ref(false)
    const isLive = ref(false)
    const isSingleCourse = ref(false)
    const isMenuDisabled = ref(false)
    const modalMenuActive = ref(false);
    const modalSyllabusActive = ref(false)
    const selectedExam = ref(null)
    const isShowStartBtn = ref(false)
    const isRunningExam = ref(false)
    const coordinates = ref({x: 0, y: 0})
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const currentPage = ref(1);
    const itemsPerPage = 10;
    const totalPages = 10;

    watch(
        () => route.path,
        async (newPath, oldPath) => {
          if (newPath != oldPath) {
            routineList.value = []
            archievedList.value = []
            resultList.value = []
            if (route.params.exam_type) {
              isSingleCourse.value = true
              await getCourse()
              title.value = store.state.titleBar ? store.state.titleBar : "লাইভ এক্সাম"
            } else {
              isSingleCourse.value = false
              title.value = store.state.titleBar ? store.state.titleBar : "লাইভ এক্সাম"
            }
            await getCourseDetails()
            refreshComponent.value++;
          }
        }
    )
    onMounted(() => {
      if (route.params.exam_type) {
        isSingleCourse.value = true
        getCourse()
        title.value = store.state.titleBar ? store.state.titleBar : "লাইভ এক্সাম"
      } else {
        isSingleCourse.value = false
        title.value = store.state.titleBar ? store.state.titleBar : "লাইভ এক্সাম"
      }
      getCourseDetails()

    })

    const getCourse = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/exam-types/" + route.params.exam_type;
      await httpClient
          .get(url)
          .then((res) => {
            if (res.success) {
              course.value = res.exams
            }
          })
    }

    const closeMenuModal = () => {
      modalMenuActive.value = false
    }

    const showMenuModal = (exam, coordinate) => {
      modalMenuActive.value = true
      modalSyllabusActive.value = false
      selectedExam.value = exam
      coordinates.value = coordinate
    }

    const closeSyllabusModal = () => {
      modalSyllabusActive.value = false
    }

    const showSyllabusModal = (exam) => {
      isShowStartBtn.value = false
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam
    }

    const showStartExamDialog = (exam) => {
      isShowStartBtn.value = true
      modalSyllabusActive.value = true
      modalMenuActive.value = false
      selectedExam.value = exam


      if (exam.ended == StaticTags.ID_EXAM_RUNNING) {
        isRunningExam.value = true
      } else {
        isRunningExam.value = false
      }
    }

    const getCourseDetails = async () => {
      const config = {
        params: {
          "exam_type": route.params.exam_type,
          "running": 1,
          "upcoming": 1,
          "previous": 0,
          "own": 0
        },
      };
      const url = process.env.VUE_APP_API_BASE_URL + "/exams";
      await httpClient
          .get(url, config)
          .then((res) => {
            if (res.success) {
              store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
              getRoutineList()
              isLive.value = true
            }
          })
    }
    const getStatus = (exam) => {
      let isEnded = exam.ended
      let isParticipated = (exam.result != null || exam.result != undefined)
      let isPass = false
      if (isParticipated) {
        isPass = exam.result.mark >= exam.cut_mark
      }
      if (isEnded == StaticTags.ID_EXAM_RUNNING) {
        if (isParticipated) {
          return "Participated";
        } else {
          return "Running";
        }
      } else if (isEnded == StaticTags.ID_EXAM_FINISHED) {
        return "Finished";
      } else if (isEnded == StaticTags.ID_EXAM_UPCOMING) {
        return "Upcoming";
      } else {
        if (isParticipated) {
          if (isPass) {
            return "Passed";
          } else {
            return "Failed";
          }
        } else {
          return "Archived";
        }
      }
    }
    const onStartExamClicked = () => {
      setExamParams({
        examId: selectedExam.value.id
      })
      setExamInfo({
        id: selectedExam.value.id,
        title: selectedExam.value.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM,
        isRunningExam: isRunningExam.value,
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }
    const getRoutineList = async () => {
      isResult.value = false
      isRoutine.value = true
      isArcheived.value = false
      isMenuDisabled.value = true
      if (!routineList.value || routineList.value.length == 0) {
        const config = {
          params: {
            "exam_type": route.params.exam_type,
            "running": 1,
            "upcoming": 1,
            "previous": 0,
            "own": 0
          },
        };

        const url = process.env.VUE_APP_API_BASE_URL + "/exams";

        await httpClient
            .get(url, config)
            .then((res) => {


              if (res.success) {
                routineList.value = groupByDate(res.exams
                )
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            })
      }
    }


    const getResultList = async () => {
      isResult.value = true
      isRoutine.value = false
      isArcheived.value = false
      isMenuDisabled.value = false
      if (!resultList.value || resultList.value.length == 0) {
        const config = {
          params: {
            "exam_type": route.params.exam_type,
            "running": 0,
            "upcoming": 0,
            "previous": 1,
            "own": 1
          },
        };
        const url = process.env.VUE_APP_API_BASE_URL + "/exams";
        await httpClient
            .get(url, config)
            .then((res) => {
              if (res.success) {
                resultList.value = groupByDate(res.exams)
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            })
      }
    }
    const getArcheivedList = async () => {
      isResult.value = false
      isRoutine.value = false
      isArcheived.value = true
      isMenuDisabled.value = false
      if (!archievedList.value || archievedList.value.length == 0) {
        const config = {
          params: {
            "exam_type": route.params.exam_type,
            "running": 0,
            "upcoming": 0,
            "previous": 1,
            "own": 0
          },
        };
        const url = process.env.VUE_APP_API_BASE_URL + "/exams";
        await httpClient
            .get(url, config)
            .then((res) => {
              if (res.success) {
                archievedList.value = groupByDate(res.exams)
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }
            })
      }
    }
    const onQuestionListClicked = () => {

    }

    const groupByDate = (items) => {
      const routineListGroup = {}
      items.forEach(item => {
        const date = item.date;

        if (!routineListGroup[date]) {
          routineListGroup[date] = [];
        }

        routineListGroup[date].push(item);
      });
      return routineListGroup
    }


    const onRankeListClicked = () => {

    }

    const paginatedRoutineList = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = {};
      const dateKeys = Object.keys(routineList);

      for (let i = startIndex; i < endIndex && i < dateKeys.length; i++) {
        const dateKey = dateKeys[i];
        paginatedData[dateKey] = routineList[dateKey];
      }
      return paginatedData;
    });
    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages) {
        currentPage.value++;
      }
    };
    return {
      course,
      totalPages,
      refreshComponent, routineList,
      archievedList,
      resultList,
      isArcheived,
      isRoutine,
      isLive,
      title,
      convertToBanglaDate, getArcheivedList,
      getRoutineList,
      getResultList,
      getStatus,
      closeMenuModal,
      showMenuModal,
      closeSyllabusModal,
      showSyllabusModal,
      onQuestionListClicked,
      onRankeListClicked,
      onStartExamClicked,
      showStartExamDialog,
      isShowStartBtn,
      modalSyllabusActive,
      selectedExam,
      modalMenuActive,
      isResult,
      isMenuDisabled,
      isRunningExam, isSingleCourse,
      isLoggedIn,
      currentPage,
      itemsPerPage,
      paginatedRoutineList,
      prevPage,
      nextPage,
      coordinates,
    }
  }


});
</script>

<style lang="scss" scoped>
.nav {
  gap: 10px;
}

.nav-tabs .nav-link {
  border-radius: 28px;
  background: #F0F5F9;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: white;
  border-radius: 28px;
  background: #000;
}

.nav-tabs {
  border-bottom: white;
}

.exam-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.container-child-1 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.container-child-2 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.live-exam-box {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .exam-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }
  .container-child-1 {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px;
  }
  .container-child-2 {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px 0 10px 0;
  }
}


.date-format {
  color: #000;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

.selected {
  background-color: rgb(1, 1, 135);
}

.btn-type {
  margin-right: 10px;
}

.no-exam-text {
  position: relative;
  width: 40%;
  text-align: center;
  top: 25%;
  left: 50%;
  padding: 20px;
  transform: translate(-50%, 0);
  opacity: 0.5;
  color: black;
}
</style>
  