<template>
    <div class="result-card">
      <div class="title">{{ item.topic }}</div>
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <div class="total-marks">{{ item.topic }}</div>
          <div class="label">Total Marks</div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="correct">{{ item.correct }}</div>
          <div class="label">Correct</div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="wrong">{{ item.wrong }}</div>
          <div class="label">Wrong</div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="total">{{ item.total }}</div>
          <div class="label">Total</div>
        </div>
      </div>
      <button class="btn btn-primary mt-3" @click="goToExamPage(item)">View Result</button>
    </div>
  </template>

<script>
import { defineComponent, onMounted } from "vue";
export default defineComponent ({
  components: {
    
  },
  props:
    ["item"]
  ,
  setup(prop, context) {
    
    onMounted(() => {
      
    });
    
    const goToExamPage = (item) => {
        
      context.emit("goToExamPage", item)
    }

    return {
      goToExamPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.result-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    .col-sm-6 {
      margin-bottom: 10px;
      .label {
        font-size: 12px;
        color: #ccc;
        margin-top: 5px;
      }
    }
    .col-md-3 {
      margin-bottom: 10px;
    }
  }
  .btn {
    width: 100%;
  }
  @media (min-width: 768px) {
    .result-card {
      .row {
        flex-wrap: wrap;
      }
      .col-sm-6 {
        flex-basis: 50%;
      }
    }
  }
}
</style>