<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid ">
    <div class="row content-center">
      <div class="col-lg-2 col-md-2 col-sm-2 "></div>
      <div class="col-lg-8 col-md-8 col-sm-8 mt-3 px-0" id="packages-section">
        <div class="package-box-list">
          <div class="p-2" v-for="pack in packages" :key="pack.id">
            <div class="modal fade" :id="'details-modal-' + pack.id" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title package-box-title" id="staticBackdropLabel">{{ pack.name }}</h5>
                  </div>
                  <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <p style="text-align: left">{{ pack.short_description }}</p>
                      <div class="d-block text-left">
                        <h5 style="text-align: left; font-weight: 600">ফুল প্যাকেজ যা যা থাকছে</h5>
                        <!--                        <p style="white-space: pre-line">{{ pack.long_description }}</p>-->
                        <p style="white-space: pre-line">
                          {{
                            showFullDescription ? pack.long_description : pack.long_description.substr(0, 170) + '...'
                          }}<a @click="toggleDescription" style="cursor: pointer; color: #005cbf">
                          {{ showFullDescription ? '  ...বন্ধ করুন' : 'আরও দেখুন' }}
                        </a>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 p-2">
                      <div class="tacbox" style="
              display: flex;
              padding: 1em;

              border: 3px solid #ddd;
              background-color: #eee;
              max-width: 800px;
              text-align: left;
            ">
                        <input type="checkbox" id="checkbox" v-model="checked" class="inp-cbx"
                               style="margin-right: 10px; margin-bottom: 10px"/>
                        <label for="checkbox">
                          I agree to these
                          <a target="_blank" href="https://bcsprostuti.com/terms-condition.html">Terms and
                            Conditions</a>,<a
                            target="_blank" href=" https://bcsprostuti.com/privacy-policy.html">Privacy policy</a>
                          and
                          <a target="_blank" href=" https://bcsprostuti.com/refund-policy.html">Refund
                            policy</a></label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 p-2" style="text-align: left">
                      <button class=" btn btn-outline-primary" type="button" :disabled="!checked"
                              @click="onPayClicked(pack.id)">
                        পে <span class="pay-dam" style="font-size: 1rem !important;" v-if="pack.dam != pack.actual_dam"> ৳{{ englishToBengaliNumber(pack.dam) }} </span>
                        <span class=" pay-actual_dam" style="font-size: 1rem !important;">{{ "\xa0" }}{{
                            "\xa0"
                          }}৳{{ englishToBengaliNumber(pack.actual_dam) }} </span>
                      </button>
                      <button type="button" class="m-3 btn btn-outline-danger" data-bs-dismiss="modal">বাতিল করুন
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="package-box" data-bs-toggle="modal" :data-bs-target="'#'+'details-modal-' + pack.id"
                 style="cursor: pointer">
              <div class="package-box-left">
                <div class="package-box-title">
                  {{ pack.name }}
                </div>
                <div class="package-box-sub-title">
                  {{ pack.short_description }}
                </div>
              </div>
              <div class="package-box-right">
                <div class="package-box-price">
                  <span class="dam" v-if="pack.dam != pack.actual_dam">৳{{ englishToBengaliNumber(pack.dam) }}</span>
                  <span class="pt-2 actual_dam">{{ "\xa0" }}{{ "\xa0" }}৳{{
                      englishToBengaliNumber(pack.actual_dam)
                    }}</span>
                </div>
                <div class="package-box-price-2">
                  <span class="pt-2 actual_dam">৳{{ englishToBengaliNumber(pack.actual_dam) }}{{ "\xa0" }}{{
                      "\xa0"
                    }}</span>
                  <span class="dam" v-if="pack.dam != pack.actual_dam">৳{{ englishToBengaliNumber(pack.dam) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 "></div>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer/>
    </div>
    <!--      Footer section end -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import SubMenu from "@/components/SubMenu.vue";
import store from '@/store/index.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {StaticTags} from "@/static";
import {useRouter} from "vue-router";
import httpClient from "@/config/httpClient";
import Swal from 'sweetalert2/dist/sweetalert2';
import {englishToBengaliNumber} from "@/config/utils";

export default defineComponent({
  components: {
    Footer,
    Header,
    SubMenu,
  },
  setup() {
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const userDetails = ref([])
    const packages = ref([])
    const checked = ref(false)
    const showFullDescription = ref(false)
    const modalOn = ref(false)
    const router = useRouter()
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "প্যাকেজ"
      getUserDetails()
      getPackages()
    });
    const onSubscribeClicked = (id) => {
      this.showModal(id);
    }
    const onPayClicked = (id) => {
      if ((!access_token) || access_token == "null") {
        store.commit("setPackId", id);
        router.push("/login");
      } else {
        buyPackage(id)
      }

    }
    const getUserDetails = async () => {
      await httpClient
          //.post(baseUrl + "api2/login/", loginParams)
          .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
          .then((response) => {
            if (response != undefined) {
              if (response.success) {
                userDetails.value = response.user
              }
            }
          })
    }

    const buyPackage = async (id) => {
      await httpClient
          .post(process.env.VUE_APP_API_BASE_URL + "/memberships/" + id)
          .then((response) => {
            if (response.success) {

              window.location.href = response.url;


            } else {
              Swal.fire({
                text: response.message,
                title: "Failed",
                icon: StaticTags.ALERT_TYPE_ERROR,
                showConfirmButton: true,
                confirmButtonText: "Okay"
              }).then((result) => {
                if (result.isConfirmed) {
                  // do nothing
                }
              })
            }
          })

    }

    const showModal = (i) => {
      // router.push("packages/"+i)
      this.$refs["modal" + i][0].show();
    }

    const hideModal = (i) => {
      this.$refs["modal" + i][0].hide();
    }

    const toggleDescription = () => {
      if (showFullDescription.value) {
        showFullDescription.value = false
      } else {
        showFullDescription.value = true
      }
    }

    const getPackages = async () => {
      await httpClient
          .get(process.env.VUE_APP_API_BASE_URL + "/packages", {
            params: {
              website: true,
              user: userDetails.value.userid,
            },
          })
          .then((response) => {
            packages.value = response.package;
          });
    }
    return {
      title,
      packages,
      checked,
      modalOn,
      showModal,
      hideModal,
      onSubscribeClicked,
      onPayClicked,
      englishToBengaliNumber,
      showFullDescription,
      toggleDescription,
    };
  },
});
</script>

<style scoped>
.package-box {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.package-box:hover {
  background-color: #0095FF;
  color: white !important;

  .package-box-title {
    color: white !important;
  }

  .package-box-sub-title {
    color: white !important;
  }

  .package-box-price {
    color: white !important;

    .dam {
      color: white !important;
    }

    .actual_dam {
      color: white !important;
    }
  }
}

.package-box-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.package-box-sub-title {
  color: #999;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.package-box-price {
  color: #0095FF;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
  display: none;
}

.package-box-price-2 {
  color: #0095FF;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
  visibility: visible;
}

.package-box-list {
  display: grid;
  //padding: 20px;
}

.dam {
  color: #807e7d;
  text-decoration: line-through;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.actual_dam {
  color: #066cbd;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@media (min-width: 768px) {
  .package-box {
    grid-template-columns: 4fr 1fr;
  }

  .package-box-price {
    display: block;
    text-align: right;
  }

  .package-box-price-2 {
    display: none;
    text-align: right;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 300px) and (max-width: 580px) {
  .dam {
    font-size: 14px !important;
  }

  .actual_dam {
    font-size: 18px !important;
  }
}



</style>