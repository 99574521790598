  import { StaticTags } from '@/static';
  import store from '@/store/index.js';
  import { createCanvas } from 'canvas';
  /**
   * Set localStorage
   */

  export const setStore = (name, content) => {
      if (!name) return;
      if (typeof content !== "string") {
        content = JSON.stringify(content);
      
      }
      return localStorage.setItem(name, content);
      
    };
    /**
     * Get localStorage
     */
    export const getStore = (name) => {
      if (!name) return;
      if (localStorage.getItem(name) != 'undefined')
        if(typeof localStorage.getItem(name) == "string")
        {
          return localStorage.getItem(name)
        }
        else
        {
          return JSON.parse(window.localStorage.getItem(name));
        }
        
      else
        return undefined;
    };
    /**
     * Clear localStorage
     */
    export const removeItem = (name) => {
      if (!name) return;
      return window.localStorage.removeItem(name);
    };
    /**
     * Validate Email address
     */
    export const isValidEmail = (value) => {
      return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value)
        ? false
        : true;
    };
    
    // calculate offset for getting time for the time
    export const calculateOffset = (val) => {
      if(val)
      {
        return Math.min(val/10.0, 1/0.6)
      }
      else return 1;
    }

    // get the time difference of two time
    export const getTimeDifference = (oldTime, newTime) => {
      return (parseFloat(newTime.getTime())-parseFloat(oldTime))/1000;
    }

    export const isCategoryListExpired = (oldTime, newTime) => {
      var expiaryTime = 24*3600*1  // set expiry time here
      var timeDiff = getTimeDifference(oldTime, newTime)
      if(timeDiff > expiaryTime)
      {
        return true
      }
      else{
        return false
      }
    }

    /**
     * 
     * set all exam information  
     */

    export const setExamInfo = ({id, examType, title, isViewOnly, time, questionNumber,page, isExam, startTime, endTime, examFlowType, isRunningExam}) => {
      let examInfo =  store.getters[StaticTags.GET_EXAM_INFO];
      examInfo.examId = id ? id: examInfo.examId ? examInfo.examId : null 
      examInfo.examTime = time ? time : examInfo.time ? examInfo.time : null
      examInfo.startTime = new Date()
      examInfo.total = questionNumber ? questionNumber : examInfo.total? examInfo.total : null
      examInfo.examType = examType ? examType : examInfo.examType
      examInfo.title = title ? title : examInfo.title
      examInfo.isViewOnly = isViewOnly ? isViewOnly : examInfo.isViewOnly
      examInfo.isExam = isExam ? isExam : examInfo.isExam
      examInfo.page = page ? page : examInfo.page
      examInfo.startTime = startTime ? startTime : examInfo.startTime
      examInfo.endTime = endTime ? endTime : examInfo.endTime
      examInfo.examFlowType = examFlowType ? examFlowType : examInfo.examFlowType
      examInfo.isRunningExam = isRunningExam != null ? isRunningExam : examInfo.isRunningExam
      dispatch({
        id : StaticTags.DISPATCH_ID_EXAM_INFO, 
        payload : examInfo,
      })
    }

    /**
     * set all exam params that need to get exam questions
     * @param {*} topic 
     * @param {*} topicId 
     * @param {*} examId 
     */
    export const setExamParams = ({topic, topicId, examId, page, questionNumber, time}) => {
      let examParams = store.getters[StaticTags.GET_EXAM_PARAMS];
      examParams.topic = topic ? topic : examParams.topic
      examParams.topicId = topicId ? topicId : examParams.topicId
      examParams.examId = examId ? examId : examParams.examId
      examParams.questionNumber = questionNumber ? questionNumber : examParams.questionNumber
      examParams.page = page ? page : examParams.page
      examParams.time = time ? time : examParams.time
      dispatch({
        id : StaticTags.DISPATCH_ID_EXAM_PARAMS,
        payload : examParams
      })
    }

    export const generateImageSrc = (text) => {
      const canvas = createCanvas(200, 50);
      const ctx = canvas.getContext('2d');

      // Set font and size
      ctx.font = '24px Arial';

      // Draw text onto the canvas
      ctx.fillText(text, 10, 30);

      // Convert canvas to a data URL (PNG format)
      return canvas.toDataURL('image/png');
  }

  // export const mergeChabi = (chabi) => {
  //   chabir_first_half = ""
  //   chabir_second_half = ""
    
  // }

  export const processBDNumbers = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');

    const bdPhoneNumberPattern = /^01[3-9]\d{8}$/;
    if (bdPhoneNumberPattern.test(cleanedNumber)) {
      const formattedNumber = `0${cleanedNumber.substr(1, 10)}`;
      return formattedNumber;
    } else {
      return "Invalid BD phone number";
    }
  }


  export const convertToBanglaDate = (englishDate) => {
    const monthsMap = {
      '01': 'জানুয়ারি',
      '02': 'ফেব্রুয়ারি',
      '03': 'মার্চ',
      '04': 'এপ্রিল',
      '05': 'মে',
      '06': 'জুন',
      '07': 'জুলাই',
      '08': 'আগস্ট',
      '09': 'সেপ্টেম্বর',
      '10': 'অক্টোবর',
      '11': 'নভেম্বর',
      '12': 'ডিসেম্বর',
    };
    const daysMap = {
      '0': 'রবিবার',
      '1': 'সোমবার',
      '2': 'মঙ্গলবার',
      '3': 'বুধবার',
      '4': 'বৃহস্পতিবার',
      '5': 'শুক্রবার',
      '6': 'শনিবার',
    };
    const englishToBengaliNumber = (number) => {
      const bengaliNumbers = {
        '0': '০',
        '1': '১',
        '2': '২',
        '3': '৩',
        '4': '৪',
        '5': '৫',
        '6': '৬',
        '7': '৭',
        '8': '৮',
        '9': '৯'
      };

      const englishNumber = String(number);
      const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

      return bengaliNumber;
    }
    const dateParts = englishDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const dayOfWeek = new Date(englishDate).getDay();
    const banglaDate = `${englishToBengaliNumber(day)} ${monthsMap[month]} ${englishToBengaliNumber(year)}, ${daysMap[dayOfWeek]}`;
    return banglaDate;
  }

  export const englishToBengaliNumber = (number) => {
    const bengaliNumbers = {
      '0': '০',
      '1': '১',
      '2': '২',
      '3': '৩',
      '4': '৪',
      '5': '৫',
      '6': '৬',
      '7': '৭',
      '8': '৮',
      '9': '৯'
    };

    const englishNumber = String(number);
    const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

    return bengaliNumber;
  }

  /**
   * 
   * change course visit flow type
   */

  // export const changeFlowType = (id,flowType) => {
  //   dispatch({
  //     id: id,
  //     payload: flowType
  //   })
  // }

    export const dispatch = ({id, payload}) => {
      store.dispatch[id, payload]
    }

    /**
     * check exam time expired or not
     * @param {*} oldTime 
     * @param {*} newTime 
     * @returns 
     */
    export const isExamTimeExpired = (oldTime, newTime) => {
      var expiaryTime = 24*3600*1  // set expiry time here
      var timeDiff = getTimeDifference(oldTime, newTime)
      if(timeDiff > expiaryTime)
      {
        return false
      }
      else{
        return true
      }
    }

    
    