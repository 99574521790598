<template>
  <div class="personal-practice-button" style="cursor: pointer;" @click="onQuestionBankSelected" >
    <div class="button-icon">
      <img class="button-image" src="@/assets/images/buttons/bank.jpeg">
    </div>
    <div class="button-title">
      <h1 class="title-text">প্রশ্ন ব্যাংক</h1>
    </div>

  </div>

  <div class="personal-practice-button" style="cursor: pointer"  @click="onExerciseSelected">
    <div class="button-icon">
      <img class="button-image" src="@/assets/images/buttons/aanushilon.jpeg">
    </div>
    <div class="button-title">
      <h1 class="title-text">অনুশীলন</h1>
    </div>

  </div>

  <div class="personal-practice-button"  style="cursor: pointer" @click="onMarkedSelected">
    <div class="button-icon">
      <img class="button-image" src="@/assets/images/buttons/mark.jpeg">
    </div>
    <div class="button-title">
      <h1 class="title-text">মার্ক করা প্রশ্ন</h1>
    </div>

  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { StaticTags, URLs } from "@/static";
import {englishToBengaliNumber, setExamInfo, setExamParams} from "@/config/utils";
// import httpClient from "@/config/httpClient";

export default defineComponent({
  props: ["fromHeader"],
  setup(props, {emit}) {
    const router = useRouter()
    const isModalVisible = ref(false);
    const selectedOption = ref(null);
    const onExerciseSelected = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_EXERCISE)
      store.dispatch("setTitleBar", "অনুশীলন")
      router.push({
        name: URLs.ROUTE_EXERCISE_CATEGORIES_NAME
      });
    }

    const onMarkedSelected = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)
      store.dispatch("setTitleBar", "মার্ক করা প্রশ্ন")
      router.push({
        name: URLs.ROUTE_FAVOURITE_CATEGORIES_NAME,
      })
    }

    const onQuestionBankSelected = () => {
      store.dispatch("setTitleBar", "প্রশ্ন ব্যাংক")
      router.push({
        name: URLs.ROUTE_QUESTION_BANK_NAME,
      })
    }

    const onOverAllExamSelected = () => {
      store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
      store.dispatch("setTitleBar", "সামগ্রিক পরীক্ষা")
      isModalVisible.value = true;
      // router.push({
      //   name: URLs.ROUTE_EXAM_CATEGORIES_NAME,
      // })
    }

    const closeModal = () => {
      isModalVisible.value = false;
      selectedOption.value = null;
      emit("close")
    }


    const startExam = async () => {

      setExamInfo({
        id: StaticTags.NO_ID,
        examType: StaticTags.EXAM_TYPE_OVER_ALL,
        title: "সামগ্রিক পরীক্ষা",
        isViewOnly: false,
        questionNumber: selectedOption.value.questionNumber,
        isExam: true,
        startTime: new Date(),
        time: selectedOption.value.time,
        examFlowType: StaticTags.TYPE_OVER_ALL_EXAM
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_OVER_ALL,
        questionNumber: selectedOption.value.questionNumber
      })

      router.push("/exam");
    }

    return {
      onMarkedSelected,
      onExerciseSelected,
      onQuestionBankSelected,
      onOverAllExamSelected,
      isModalVisible,
      closeModal,
      startExam,
      englishToBengaliNumber,
      selectedOption,
      options: [{ time: 25, questionNumber: 50 }, { time: 50, questionNumber: 100 }, {time: 100, questionNumber: 200 }],
    }
  },
  methods: {
    handleClick(index) {
      this.selectedOption = this.options[index];
    },
    isSelected(option) {
      if(this.selectedOption == null) {
        return false;
      }
      return this.selectedOption.time === option.time
    }
  }


});

</script>
<style scoped>
.selected-option{
  background-color: rgba(100, 95, 95, 0.55) !important;
  color: #ffffff !important;
}
.option-group{
  margin: 10px 0;
  text-align: left;
  padding: 4px;
  border-radius: 20px;
  border: 1px solid;
  background: aliceblue;
}

.form-check-input{
  margin-right: 10px;
}
.form-check-input-2{
  margin-right: 10px;
  position: absolute;
  margin-left: -1.25rem;
}

.form-check-label{
  font-size: 18px;
  font-weight: 600;
}


.modal-content {
  border-radius: 24px;
}
.practice-close-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}
.practice-start-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}

.modal-header {
  text-align: center;
  justify-content: center;
  border-bottom: 0px solid #e9ecef;
}
.modal-footer {
  text-align: center;
  justify-content: center;
  border-top: 0px solid #e9ecef;
}
.modal-body {
  padding: 2rem;
}
.modal-content-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
}
.content-sub-title {
  color: #00A8A8;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(14 13 13 / 71%);
}


.personal-practice-button {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
//width: 300px;
  height: 80px;
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1.1fr 2fr;
  grid-gap: 2px;
}

.button-icon {
  background: #fff5f6;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  position: relative;
  left: 23px;
}
.button-image {
  width: 30px;
  height: 30px;
  position: relative;
  top: 15px;
  left: 3px;
}
.title-text {
  color: #010101;
  text-align: left;
  font: 600 18px/21px "Noto Sans Bengali", sans-serif;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  top: 19px;
}

@media (max-width: 576px) {
  .modal-dialog {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
  }
  .modal-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    gap: 10px;
  }
}

@media (max-width: 768px) {

  .personal-practice-button {
    height: 60px;
  }

  .practice-close-btn {
    width: 100%;
  }

  .practice-start-btn {
    width: 100%;
  }

  .button-icon {
    width: 30px;
    height: 30px;
    left: 7px;
  }
  .button-image {
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    left: 0px;
  }
  .title-text {
    font: 600 13px/21px "Noto Sans Bengali", sans-serif;
    top: 7px;
  }

}
</style>