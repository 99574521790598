<template>
    <div class="card col-md-8 mx-auto mb-3" >
      
      <div class="card-header bg-light justify-content-start text-left">
        <h4 class="question-title"><span class="serial-number">{{ questionIndex + 1 + "). " }} </span> {{ question.question }}</h4>

      </div>
      <div v-if="question.question_img && question.question_img !=''">
        <img :src="question.question_img" alt="image" style="width: 100%">
      </div>
      <div class="card-body">
        <div class="row question-item justify-content-start">
          <div v-for="(option, index) in question.options" :key="index" class="col-md-6">
            <label class="option form-check" :class="{
                'disabled': viewOnly,
                'showcorrect':(isResultMode && option.answer === 1),
                'correct': !isExamMode && answerMarked && selectedOption.answer === 1 && option.answer === 1,
                'incorrect': !isExamMode && answerMarked && (selectedOption != '' && selectedOption.choice[0].value == option.choice[0].value && option.answer != 1)
              }">
              <input type="radio" :name="'question-' + questionIndex" :value="option" v-model="selectedOption"
                @change="markAnswer(index, questionIndex)" :disabled="answerMarked || isDisable || viewOnly"
                class="form-check-input">
              <span class="option-text form-check-label" style="color: black; font-size: 17px;">{{ option.choice[0].value
              }}</span>
            </label>
          </div>
        </div>
        <div v-if="(isExerciseMode != undefined && isExerciseMode && answerMarked) || isResultMode">
          <br>
          <div v-if="question.explanation_img && question.explanation_img != ''">
            <img :src="question.explanation_img" alt="image" style="width: 100%">
          </div>
          <p style=" text-align: justify; padding: 10px;">ব্যাখ্যা: {{ question.explanation }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted } from 'vue'
  import httpClient from "@/config/httpClient";
  import { StaticTags } from '@/static/index.js'
  import Swal from 'sweetalert2/dist/sweetalert2';
  export default defineComponent({
    components: {
      
    },
  
    props:
      ["questionIndex", "isExamMode", "isExerciseMode", "question", "questionWithState", "viewOnly", "isMenuShowAble", "isResultMode"]
    ,
    setup(props, context) {
      const selectedOption = ref('');
      const answerMarked = ref(false);
      const showableOptions = ref()
      const isDisable = ref(false)
      const question = ref(props.question)
      const reportModalActive = ref(false);
      const showMenuList = ref(false)
      const menuOptions = [
        {
          title: StaticTags.QUESTION_REVIEW_TITLE,
          action: StaticTags.ACTION_REVIEW
        },
        {
          title: StaticTags.QUESTION_REMOVE_TITLE,
          action: StaticTags.ACTION_REMOVE
        },
        {
          title: StaticTags.QUESTION_REPORT_TITLE,
          action: StaticTags.ACTION_REPORT
        },
        {
          title: StaticTags.QUESTION_SEARCH_TITLE,
          action: StaticTags.ACTION_SEARCH
        }
      ]
  
      onMounted(() => {
        
        if (props.isResultMode && !props.isExerciseMode) {
          answerMarked.value = true;
          disabledOptionsWithCorrectAnswer();
        }
      });
  
      const toggleShowMenueList = () => {
  
        showMenuList.value = !showMenuList.value
  
      }
  
      const showReportModal = () => {
        reportModalActive.value = true
      }
  
      const closeReportModal = () => {
        reportModalActive.value = false
      };
      const reportQuestionFunction = () => {
        showReportModal()
      }
  
      const disabledOptionsWithCorrectAnswer = () => {
        props.question.options.forEach((option, index) => {
          if (props.question.mark - 1 == index) {
            selectedOption.value = option
            //console.log(selectedOption.value)
          }
          //console.log(option)
        })
        //console.log(selectedOption.value)
  
        disableOptions()
      }
  
      const onClickOption = (action) => {
  
        switch (action) {
  
          case StaticTags.ACTION_REVIEW:
            markForReviewFunction(question.value.id, question.value.category_id)
            break
  
          case StaticTags.ACTION_REMOVE:
            removeReviewFunction(question.value.id, question.value.category_id)
            break
  
          case StaticTags.ACTION_REPORT:
            reportQuestionFunction(question.value)
            break
  
          case StaticTags.ACTION_SEARCH:
            //
            break
        }
      }
  
  
      const reportSubmit = (reportModalData) => {
        closeReportModal()
  
        const url = process.env.VUE_APP_API_BASE_URL + "/reports";
        const data = {
          "correct": reportModalData.selectedOption,
          "explanation": reportModalData.inputValue,
          "question": question.value.id
        }
  
  
  
        httpClient
          .post(url, data)
          .then((res) => {
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })
  
      }
  
  
      const markForReviewFunction = (id, topicId) => {
  
        const url = process.env.VUE_APP_API_BASE_URL + "/favourites";
        const data = {
          topic_id: topicId,
          question: id,
        }
  
  
        httpClient
          .post(url, data)
          .then((res) => {
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })
  
      }
  
      const removeReviewFunction = (id, topicId) => {
  
        const url = process.env.VUE_APP_API_BASE_URL + "/favourites";
        const data = {
          topic_id: topicId,
          question: id,
        }
  
  
        httpClient
          .put(url, data)
          .then((res) => {
            context.emit('updateQuestions')
            toggleShowMenueList()
            Swal.fire({
              text: res.message,
              title: "Successful",
              icon: StaticTags.ALERT_TYPE_SUCCESS
            })
          })
  
      }
  
      function markAnswer(answerIndex, questionIndex) {
        answerMarked.value = true;
        context.emit("onAnswered", props.question.id, answerIndex + 1, questionIndex) // answer list is 1 based
        disableOptions();
      }
  
      function disableOptions() {
        // const options = event.target.parentElement.parentElement.querySelectorAll('input[type="radio"]');
        // options.forEach(option => {
        //   option.disabled = true;
        // });
        isDisable.value = true
      }
  
      return {
        selectedOption,
        answerMarked,
        menuOptions,
        showMenuList,
        toggleShowMenueList,
        markAnswer,
        disableOptions,
        showableOptions,
        onClickOption,
        isDisable,
        reportSubmit,
        reportModalActive,
        closeReportModal,
  
      };
    },
  });
  
  </script>
  
  <style lang="scss">
  .question-item {
    text-align: left;
    padding-left: 5px;
  }
  
  .question-padding-width {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 66.666667%
  }
  
  .card .option {
    padding: 0rem !important;
    margin: 0.5rem;
    border-radius: 0.25rem;
  }
  
  @media (max-width: 850px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 60% !important;
    }
  }
  
  @media (max-width: 768px) {
    .question-item {
      text-align: left;
      padding-left: 2px;
      margin: 3px
    }
  
    .card .option {
      padding: 0rem !important;
    }
  
    .question-padding-width {
      padding: 0;
      max-width: 70%
    }
  
    .question-padding-width-answer {
      padding: 0;
      max-width: 52% !important;
    }
  }
  
  @media (max-width: 668px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 48% !important;
    }
  }
  
  @media (max-width: 636px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 44% !important;
    }
  }
  
  @media (max-width: 599px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 40% !important;
    }
  }
  
  @media (max-width: 535px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 37% !important;
    }
  }
  
  @media (max-width: 499px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 33% !important;
    }
  }
  
  @media (max-width: 450px) {
    .question-padding-width-answer {
      padding: 0;
      max-width: 30% !important;
    }
  }
  
  @media (max-width: 399px) {
    .question-padding-width {
      padding: 0;
      max-width: 95%
    }
    .question-padding-width-answer {
      padding: 0;
      max-width: 27% !important;
    }
  }
  
  @media (max-width: 350px) {
    .question-padding-width {
      padding: 0;
      max-width: 80%
    }
    .question-padding-width-answer {
      padding: 0;
      max-width: 24% !important;
    }
  }
  
  @media (max-width: 300px) {
    .question-padding-width {
      padding: 0;
      max-width: 65%
    }
    .question-padding-width-answer {
      padding: 0;
      max-width: 21% !important;
    }
  }
  
  @media (max-width: 250px) {
    .question-padding-width {
      padding: 0;
      max-width: 60%
    }
    .question-padding-width-answer {
      padding: 0;
      max-width: 15% !important;
    }
  }
  
  @media (max-width: 220px) {
    .question-padding-width {
      padding: 0;
      max-width: 50%
    }
    .question-padding-width-answer {
      padding: 0;
      max-width: 13% !important;
    }
  }
  
  .card {
    margin-top: 1rem;
    margin-bottom: 1rem;
  
    .option {
      padding: 1rem;
      margin: 0.5rem;
      border-radius: 0.25rem;
  
      &.correct {
  
        //background-color: #c3e6cb;
        .option-text {
          color: limegreen !important;
        }
      }
  
      &.showcorrect {
  
        //background-color: #c3e6cb;
        .option-text {
          color: limegreen !important;
        }
      }
  
      &.selected {
  
        input[type='radio'] {
          color: #f5c6cb;
        }
  
        .option-text {
          color: limegreen !important;
        }
      }
  
      &.incorrect {
        //background-color: #f8d7da;
        //
        //input[type='radio'] {
        //  border-color: #f5c6cb;
        //}
  
        .option-text {
          color: #f6071d !important;
        }
      }
  
      &.disabled {
        input[type='radio'] {
          cursor: not-allowed;
        }
  
        .option-text {}
      }
    }
  }
  </style>