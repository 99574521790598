<template>
  <!--      Header section start -->
  <Header />
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="title"></SubMenu>

  <div class="container-fluid question-bank-section">
    <div class="subjective-title-2 text-center">
      <h2 class="top-title-div" >{{title}}</h2>
    </div>
    <div class="row content-center">
        <div class="package-box-list">
          <div class="p-2" v-for="item in questionBank" :key="item.id">
            <div class="package-box" style="cursor: pointer" @click="goToDetails(item)">
              <div class="package-box-left">
                <div class="package-box-title">
                  {{ item.title }}
                </div>
                <div class="package-box-sub-title">
                  সর্বমোটঃ {{ englishToBengaliNumber(item.running) }}
                </div>
              </div>
              <div class="package-box-right">
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer />
    </div>
    <!--      Footer section end -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import SubMenu from "@/components/SubMenu.vue";
import store from '@/store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {StaticTags, URLs} from "@/static";
import httpClient from "@/config/httpClient";
// import {useRouter} from "vue-router";
import Swal from 'sweetalert2/dist/sweetalert2';
import {englishToBengaliNumber} from "@/config/utils";
import router from "@/router";
export default defineComponent({
  components: {
    Footer,
    Header,
    SubMenu,
  },
  setup() {
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    const questionBank = ref([])
    // const router = useRouter()
    onMounted(() => {
      title.value = store.state.titleBar ? store.state.titleBar : "প্রশ্ন ব্যাংক"
      getCourseTypes()
    });

    const getCourseTypes = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/exam-types";
      const config = {
        params: {
          creator_id: -1,  // -1 for important courses documented in the api
          state: "ALL_TIME_RUNNING"
        },
      };
      if (isLoggedIn) {
        await httpClient
            .get(url, config)
            .then((res) => {
              // console.log("Response",res)
              if (res.success) {
                questionBank.value = res.exam_types;
                console.log("questionBank", questionBank.value)
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }

            })
      }
    }

    const goToDetails = (item) => {
      store.dispatch("setTitleBar", item.title)
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_LIVE_COURSE)
      router.push({
        name: URLs.ROUTE_QUESTION_BANK_DETAILS_NAME,
        params: {
          id: item.id,
        },
      })
      // router.push({name: "QuestionBankDetails", params: {id: item.id}})
      console.log("item", item);

    }


    return {
      title,
      access_token,
      englishToBengaliNumber,
      questionBank,
      goToDetails
    };
  },
});
</script>



<style scoped>
.question-bank-section {
  min-height: 80vh;
}
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.package-box {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: grid;
  grid-gap: 20px;
  justify-content: left;
  align-items: self-start;
  padding: 20px;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
}

.package-box:hover {
  background-color: #FA3434;
  color: white !important;

  .package-box-title {
    color: white !important;
  }

  .package-box-sub-title {
    color: white !important;
  }

  .package-box-price {
    color: white !important;

    .dam {
      color: white !important;
    }

    .actual_dam {
      color: white !important;
    }
  }
}

.package-box-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.package-box-sub-title {
  color: #FA3434;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.package-box-list {
  display: grid;
  padding: 20px;
}

@media (min-width: 768px) {
  .package-box {
    grid-template-columns: 1fr auto;
  }
  .package-box-list {
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}


</style>