<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->
  <!--  Exam Header start-->

  <SubMenu :title="'FAQ'"></SubMenu>
  <div class="container-fluid">
    <div class="container text-left" style="font-family: 'Noto Sans Bengali' ">

      <h1 class=" text-left" style="font-size: 24px; padding: 40px 0px 40px 0px;">Frequently Asked <span style="color: #0095FF;">Questions</span></h1>
      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer">বিসিএস প্রস্তুতি অ্যাপটি কি? কাদের জন্য এই অ্যাপ? </label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              বিসিএস সহ যে কোন ধরণের সরকারী চাকুরীর প্রস্তুতির জন্যই এই অ্যাপ 💯। আপনার মোবাইলে যে কোন জায়গা থেকে
              লক্ষাধিক পরীক্ষার্থীদের সাথে বিভিন্ন রকম লাইভ পরীক্ষা, টপিক কিংবা বিষয়ভিত্তিক পরীক্ষা দিয়ে নিজেকে
              প্রতিনিয়ত যাচাই করার জন্যই একটি এন্ড্রয়েড অ্যাপ বেইজড অনলাইন প্লাটফর্ম:<b> বিসিএস প্রস্তুতি।</b>
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-2">আমি নতুন করে প্রস্তুতি শুরু করতে চাচ্ছি। কিভাবে শুরু করতে পারি?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              প্রস্তুতি’র শুরুতেই দেখে নিন বিসিএস ক্যাডারদের এনালাইসিসে তৈরি বিসিএস প্রস্তুতি অ্যাপ এর <b>প্রিমিয়াম গাইডলাইন</b> 📚।
            </p>
            <p> গাইডলাইনে থাকছেঃ
              <ul class="text-left">
                <li>বিসিএস প্রিলিমিনারি সম্পূর্ণ সিলেবাস টপিক অনুসারে ভেঙে ভেঙে মার্ক ডিস্ট্রিবিউশন</li>
                <li>কোন কোন বই পড়বেন সেজন্য রেফারেন্স বুক</li>
                <li>টপিক অনুসারে বিগত বছরের প্রশ্ন বিশ্লেষণ</li>
                <li>কোন টপিক থেকে কেমন প্রশ্ন আসে তার পরিসংখ্যান</li>
                <li>কোন টপিকে বেশি গুরুত্ব দেবেন সেটার দিকনির্দেশনা 🚨</li>
              </ul>
            </p>
            <p>
              ✅ গাইডলাইন লিংক: <a target="_blank" href="https://cutt.ly/38jNDBb">https://cutt.ly/38jNDBb</a>
            </p>
            <p>
              👉 তাছাড়া অ্যাপ এর রেগুলার ২ টি কোর্স -<b> বিসিএস প্রস্তুতি (১২০ দিন) বা বিসিএস প্রস্তুতি (৬ মাস)</b> এর যে কোন
              একটা কোর্স খুব ভাল করে ফলো করতে পারেন। কথা দিচ্ছি, সম্পূর্ণ কোর্স শেষ হলে ভাল একটা প্রস্তুতি আপনার হয়ে
              যাবে।<br/>
            </p>
            <p>
              👉 আমাদের রেগুলার লাইভ এক্সামগুলো এবং বিগত সালের কোয়েশ্চনগুলো দেখলেই কোয়েশ্চন এর ধরন আপনি সহজেই বুঝতে
              পারবেন। লাইভ পরীক্ষায় অংশ নেয়ার আগে আমাদের বিষয়ভিত্তিক পরীক্ষা গুলো দিয়ে দেখুন। টপিক অনুসারে নিজেই সময় সেট
              করে যাচাই করে নিতে পারবেন ঐ নির্দিষ্ট বিষয়ে আপনার প্রস্তুতি।
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-3">আমি এখনও ছাত্র, আমি কিভাবে বিসিএস প্রস্তুতি শুরু করব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              বিসিএস এখন অনেক অনেক বেশী প্রতিযোগীতাপূর্ণ একটা পরীক্ষা। তাই আগে থেকেই বিসিএস এর জন্য নিজেকে প্রস্তুত করা
              খুবই বিচক্ষণ একটি সিদ্ধান্ত। ✅ যদি আপনি এখনো একজন ছাত্র হন, তাহলে এখন থেকে ম্যাথ আর ইংলিশ এর ওপর জোর দিন।
              রেগুলার বাংলা ও ইংরেজি পত্রিকা পড়ুন। বেশী বেশী ইংরেজি ও বাংলা বই পড়ুন। মুক্তিযুদ্ধ নিয়ে যত উপন্যাস আছে
              সেগুলা পড়ুন। রবীন্দ্রনাথ, সমরেশ মজুমদার, শীর্ষেন্দু, শরৎচন্দ্র এদের বই পড়ুন। ইংলিশ বই হ্যারি পটার দিয়ে
              শুরু করুন। বেশী বেশী করে ইংলিশ মুভি আর ইউটিউবে ইংলিশ টেড টক শুনুন। এতে ইংলিশ বলা এবং লিখা দুটোতেই কাজে
              দেবে। আর নিয়মিত গণিত চর্চা টা চালু রাখুন। আপাতত অনার্স শেষ হবার আগ পর্যন্ত এগুলোই করুন। পরে আপনার দুর্বলতা
              কই, সেটা ঠিক করতে কি করতে হবে নিজেই বুঝে যাবেন। ✊
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-4">আমি চাকরি করি, চাকুরীর পাশাপাশি কিভাবে পড়াশোনা করব বুঝতে পারছি না?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি চাকুরী করেন তার মানে আপনি চাকুরীর পাশাপাশি পড়াশোনা করবেন। সেক্ষেত্রে আপনার দিনে পড়ার জন্য সময় পাবেন
              অন্যদের তুলনায় কিছুটা কম। তাই ঠিক আপনাদের কথা চিন্তা করেই আমরা নিয়ে এসেছি <b>‘বিসিএস প্রস্তুতি (৬ মাস)’</b>
              কোর্সটি 📌। খুব অল্প সিলেবাসে দৈনিক ২-৩ ঘণ্টাতেই শেষ করা যায়: এমনভাবেই তৈরি করা হয়েছে সম্পূর্ণ সিলেবাস এবং
              রুটিন। এই কোর্সটি ফলো করলেই আপনি চাকুরীর পাশাপাশি একটা ভালো প্রস্তুতি নিয়ে নিতে পারবেন। তাছাড়া, আপনি
              আমাদের অ্যাপস এর লেকচার অ্যান্ড নোটস অপশনে গিয়ে গাইডলাইনগুলো 📚 ফলো করতে পারেন।
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-5" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-5">বিসিএস প্রস্তুতি অ্যাপ এর কোন কোর্স আমার জন্য সব চেয়ে উপযোগী?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              ধরা যাক,
            </p>
            <p>
              <ul class="text-left">
                <li>বিসিএস প্রস্তুতি শুরুতে আপনি একদম নতুন</li>
                <li>ইতোমধ্যেই আপনি কোন একটি চাকরী করছেন</li>
                <li>আপনি অনার্স ৩য় বর্ষ বা ফাইনাল ইয়ারের ছাত্র</li>
              </ul>
            </p>
            <p>
              এর যে কোন একটি যদি আপনার বেলায় প্রযোজ্য হয়, তাহলে <b>বিসিএস প্রস্তুতি ৬ মাসের</b> কোর্সটি আপনার জন্য সবচেয়ে
              উপযোগী। 🤘
            </p>
            <p>
              এ ছাড়াও পুরাতন বা নতুন সবার জন্য আছে আরও একটি কোর্স:<b> বিসিএস প্রস্তুতি (১২০ দিন) 💡।</b>
              আপনার হাতে যদি প্রতিদিন পড়ার মত যথেষ্ট পরিমাণ সময় থাকে, তাহলে বিসিএস প্রস্তুতি (১২০ দিন) কোর্সটি ফলো করুন।
            </p>
            <p>
              যারা অন্তত একবার বিসিএস প্রিলিমিনারি পরীক্ষা দিয়েছেন কিংবা যারা অন্তত বিসিএস প্রিলিমিনারি সিলেবাস একবার
              শেষ করেছেন; তাদের জন্যে সব চেয়ে উপযোগী <b>বিসিএস প্রস্তুতি রিভিশন [পুরাতনদের] 💡।</b> এই কোর্সটি এমনভাবে সাজানো
              হয়েছে যেন প্রিলি পড়ার সাথে সাথে লিখিত সিলেবাসও শেষ হয়ে যায়। প্রতিদিন বিসিএস প্রিলিমিনারি পরীক্ষার ২০০
              নাম্বারের আদলে আনুপাতিক হারে ৫০ মার্কের পরীক্ষা ডেইলি মডেল টেস্ট দিতে থাকুন ✍️। আর মূল পরীক্ষার আগে ফাইনাল
              মডেল টেস্ট তো আছেই। ব্যাংক এবং প্রাইমারি প্রস্তুতি এর জন্যও আলাদা আলাদা কোর্স আছে। ফলো করতে পারেন আপনার
              জন্য যেটা দরকার ঠিক সেটাই। ✅
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-6" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-6">আমি অনেকদিন ধরেই চেষ্টা করছি, কোন ভাবেই ভাল ফলাফল করতে পারছি না। কি করব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              প্রচুর পরিমাণে পড়াশোনা করার কোন বিকল্প নেই। সাথে বিকল্প নেই পরীক্ষা দেয়ার। আপনি যত বেশী পড়বেন তত বেশী ভালো
              করবেন ✌️। আর যত পরীক্ষা দেবেন, তত আপনার উত্তীর্ণ হবার সম্ভাবনা বাড়বে। কোথায় কোথায় বেশী ভুল করেন সেগুলোর
              দিকে বেশী করে নজর দিতে হবে। ওগুলো নিয়ে কাজ করতে হবে। ✊

            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-7" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-7">বিসিএস প্রস্তুতি অ্যাপ কিভাবে ইউজ করলে আমি সবচেয়ে ভাল করতে পারব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি যেই টপিক কিংবা চ্যাপ্টার পড়ছেন, সেটা পড়ার পর অ্যাপে ঢুকে টপিক-ভিত্তিক পরীক্ষা যত বেশি দিবেন তত বেশি
              উপকার পাবেন। পরীক্ষাতে যা যা ভুল করছেন, সেগুলো অ্যাপেই মার্ক করে রেখে দিন। এরপর রিভিশন দিন। এছাড়াও আপনি
              যেই টপিক পড়া আছে সেগুলো অ্যাপ এর আর্কাইভ থেকে পরীক্ষা দিয়ে প্রতিনিয়ত নিজেকে যাচাই করবেন। সাথে আপনার জন্য
              উপযোগী একটি কোর্স খুব ভাল করে ফলো করুন 👍।
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-8" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-8">আমার প্রস্তুতি ভাল, আমার এখন কিভাবে আগালে ভাল হবে?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              অ্যাপে প্রচুর পরিমাণে পরীক্ষা দিতে থাকুন। যত বেশি সম্ভব এবং যত ধরনের পরীক্ষা আছে সব পরীক্ষা দিন ✍️।
              বিসিএসে ভালো করার জন্য পরীক্ষা দেয়ার কোন বিকল্প নেই। আর পরীক্ষাতে যেসব ভুল প্রতিনিয়ত করছেন ওগুলো ফোকাস করে
              কাজ করুন। সাথে আপনার জন্য উপযোগী একটি কোর্স খুব ভাল করে ফলো করুন। 👊
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer" style="width: 100%">
        <input class="faq-drawer__trigger" id="faq-drawer-9" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-9">বিসিএস এর জন্য কি পড়ব কই থেকে পড়ব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি আমাদের অ্যাপস এর লেকচার অ্যান্ড নোটস সেকশন এ গিয়ে বিসিএস গাইডলাইন 📚 ফলো করুন।
            </p>
            <p>
              ✅ গাইডলাইন লিংক: <a target="_blank" href="https://cutt.ly/38jNDBb">https://cutt.ly/38jNDBb</a>
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-10" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-10">বিসিএস এর পাশাপাশি ব্যাংক প্রস্তুতি কিভাবে নিতে পারি?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি আমাদের অ্যাপ থেকে <b>‘ব্যাংক প্রশ্ন-ব্যাংক’</b> গুলো দেখে ব্যাংক এক্সামের কোয়েশ্চন সম্পর্কে ধারনা নিতে
              পারবেন। আপনি খুব সহজেই বুঝতে পারবেন, কোন ধরনের কোয়েশ্চনগুলো ব্যাংক এক্সাম গুলোতে আসে। আমাদের
              প্রশ্ন-ব্যাংক সলভ করুন। ডেডিকেটেডভাবে ব্যাংক প্রস্তুতি নিতে আমাদের <b>ব্যাংক প্রস্তুতি (৮০ দিন)</b> কোর্সটি ফলো
              করুন। পাশাপাশি বিসিএস এর জন্য তখন বিসিএস প্রস্তুতি কোর্স 120 দিন/বিসিএস প্রস্তুতি কোর্স ৬ মাস ফলো করলেই
              হয়ে যাবে। 🙂
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-11" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-11">আমি গণিতে দুর্বল, কিভাবে গণিতে ভাল করতে পারি?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি যদি গণিতে দুর্বল হয়ে থাকেন তাহলে সবার প্রথম যে কাজটি করা দরকার: সেটা হচ্ছে আপনার বেসিক ঠিক করা।
              সেজন্য আপনাকে গণিতের বোর্ড বই (৬-১০) শ্রেণি পর্যন্ত উদাহরণসহ সব অংক করে ফেলতে হবে, কিছুই বাদ দেয়া যাবে না
              📐। এরপর অ্যাপে এসে গণিতের টপিক ধরে ধরে ম্যাথ করতে হবে অনুশীলনে গিয়ে ✍️। এরপর যেগুলো পারবেন না, সেগুলো কেন
              পারছেন না, সেটা নিয়ে আপানকে কাজ করতে হবে। আর এ জন্য ব্যখ্যাগুলো খুব ভালো করে পড়তে হবে। গণিত হচ্ছে
              প্র্যাকটিসের বিষয়, প্রচুর প্র্যাকটিস করুন। </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-12" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-12">আমি ইংরেজিতে দুর্বল, কিভাবে ইংরেজিতে ভাল করতে পারি?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              ইংরেজিতে দুর্বল হলে আগে কোন একটা গ্রামার বইকে মেইন বই ধরে টপিক বাই টপিক শেষ করতে হবে। সাথে প্রচুর পরিমাণে
              অনুশীলন করতে হবে। রেগুলার ইংরেজি লিখা পড়তে হবে এবং লিখতে হবে ✍️। ইংলিশে ইউটিউবে রেগুলার টেড টক শুনতে হবে।
              সবচেয়ে ভালো হয় ইংরেজি বই পড়া শুরু করলে। মনে রাখবেন পরীক্ষা দেবার কোন বিকল্প নেই। রেগুলার টপিক ধরে ধরে
              পরীক্ষা দিতে হবে। </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-13" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-13"> বিসিএস এর জন্য সাধারণ জ্ঞান সাম্প্রতিক বিশ্ব নিয়ে কিভাবে প্রস্তুতি নেব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              বিসিএসে সাম্প্রতিক বিশ্ব থেকে আসলে খুব বেশি প্রশ্ন আসে না। যা আসে তার বেশিরভাগই টপিক রিলেটেড। তাই
              এক্ষেত্রে রেগুলার পত্রিকা পড়ার কোন বিকল্প নেই। এছাড়াও কারেন্ট এফেয়ার্স প্রতিমাসেই পড়া যেতে পারে। </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-14" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-14">জব সল্যুশন পড়লেই কি হয়ে যাবে, নাকি সাথে আরও কিছু পড়তে হবে?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আমাদের অনুশীলন সেকশনটা সব ধরনের গুরুত্বপূর্ণ কোশ্চেন গুলোর সমন্বয়ে সাজানো হয়েছে ✅। আপনি যদি আমাদের
              অনুশীলন সেকশন থেকে প্রতিটা প্রশ্ন গুরুত্ব দিয়ে ব্যাখ্যা সহ পড়েন, তাহলে অবশ্যই যে কোন সরকারি চাকরির
              পরীক্ষার জন্য আপনার ভালো একটা প্রিপারেশন হয়ে যাবে ✊। এর পাশাপাশি আপনি আমাদের অ্যাপের লেকচার এন্ড নোট
              সেকশনে গিয়ে বিসিএস গাইডলাইন ফলো করে বই সিলেক্ট করে পরতে পারেন। এই দুটো বিষয় কম্বাইন করে পড়েন আপনার অনেক
              সুন্দর একটি প্রস্তুতি হয়ে যাবে ✌️। </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-15" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-15">প্যাকেজ চালু করলে কি কি সুবিধা পাবো?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি প্যাকেজ কিনে থাকলে আমাদের চলমান সব কোর্সের এক্সেস পাবেন সাথে সব এক্সাম অ্যাটেন্ড করতে পারবেন ✅। আপনি
              যদি কোনও এক্সাম মিস করে থাকেন, তাহলে আর্কাইভ এ গিয়ে সেগুলো আবার দিতে পারবেন। এছাড়া অনুশীলন অপশন এ গিয়ে
              আপনি অনুশীলন এবং টপিক অনুসারে বিষয়ভিত্তিক এক্সামগুলো দিতে পারবেন। সর্বোপরি আপনি আমাদের অ্যাপস এর
              সবকিছুতেই এক্সেস পেয়ে যাবেন।
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-16" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-16">1 বছরের প্যাকেজ এ কি কি থাকছে?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              প্যাকেজ কিনলেই আপনি সম্পূর্ণ অ্যাপ এর এক্সেস পেয়ে যাবেন ১ বছরের জন্য। এখন যে কোর্স গুলো চলছে যেমন বিসিএস
              প্রস্তুতি (১২০ দিন), বিসিএস প্রস্তুতি (৬ মাস), এখন চলমান/আর্কাইভে থাকা সকল কোর্স সহ আগামীতে যত কোর্স যুক্ত
              হবে সকল কোর্স এর ফুল এক্সেস থাকবে। বিসিএস প্রস্তুতি অ্যাপ এর সব চেয়ে ব্যবহৃত ফিচার বিষয়ভিত্তিক পরীক্ষারও
              ফুল এক্সেস থাকবে ১ বছরের জন্য। এ ছাড়া অনুশীলন, প্রশ্ন ব্যাংক, কুইজ এবং লেকচার এন্ড নোটস এর সকল কনটেন্টে
              ফ্রি এক্সেস থাকবে। ✅
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-17" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-17">বিসিএস প্রস্তুতি অ্যাপে কিভাবে প্যাকেজ চালু করব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আমাদের অ্যাপে প্রবেশ করে নিচের দিকে "আরও" সেকশনে গিয়ে উপরে "প্যাকেজ" অপশন থেকে যে কোন একটি প্যাকেজ এ ক্লিক
              👆 করলেই পেমেন্ট এর অপশন পেয়ে যাবেন। পেমেন্ট করে সাবস্ক্রাইব করে নিতে পারেন আপনার ইচ্ছেমত যে কোন প্যাকেজ।
              👍
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-18" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-18">প্রোমো কোড কিভাবে ইউজ করব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আমাদের অ্যাপের "আরও" সেকশনে গিয়ে "প্যাকেজ" অপশন থেকে যে কোন একটি প্যাকেজ এ ক্লিক করলেই পেমেন্ট এর অপশন
              পেয়ে যাবেন। আপনার পছন্দের প্যাকেজটি ক্লিক করলেই নিচে দেখতে পাবেন "প্রোমোকোড" লেখা আছে। প্রোমোকোড এর
              জায়গায় আপনার প্রোমোকোডটি টাইপ করে পাশে "অ্যাড" বাটন এ ক্লিক করলেই প্রোমোকোডটি অ্যাড হয়ে যাবে 🎉।
              প্রোমোকোডটি অ্যাড হয়ে গেলেই নিচে ডিসকাউন্ট প্রাইস দেখতে পারবেন। ডিসকাউন্ট প্রাইস দেখে এরপরে নিচে "এগিয়ে
              যান" বাটন এ ক্লিক করলেই পেমেন্ট অপশন পেয়ে যাবেন। পেমেন্ট করে সাবস্ক্রাইব করে নিতে পারবেন আপনার পছন্দের
              প্যাকেজ। 👍
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-19" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-19">আমার বিকাশ বা নগদ একাউন্ট নাই। আমি কি প্যাকেজ এক্টিভ করতে পারব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি নিজের বা অন্য কারো বিকাশ বা নগদ একাউন্ট ব্যাবহার করে প্যাকেজ এক্টিভ করতে পারবেন ✅। পেমেন্ট এর পর
              অ্যাপ থেকেই প্যাকেজটি অটোমেটিক এক্টিভ হয়ে যাবে।
            </p>
            <p>
              এ ছাড়া আপনি যে কোন বিকাশ বা নগদ মার্চেন্ট পয়েন্ট থেকে টাকা বিকাশ বা নগদ করতে পারেন। এতে আপনার প্যাকেজটি
              ম্যানুয়ালি এক্টিভ করে দেয়া হবে।
            </p>
            <p>
              👉 Bkash/Nagad - 01313899797 [merchant]
            </p>
            <p>
              আপনি যে প্যাকেজ এক্টিভ করতে চান সেই অনুসারে Bkash বা Nagad থেকে উপরের নম্বরে পেমেন্ট করবেন। এরপর প্যাকেজের
              নাম, পেমেন্ট এর Transaction ID এবং User ID টি আমাদের ফেসবুক পেজের ইনবক্সে বা হোয়াটসঅ্যাপে দিলেই আমাদের টিম
              থেকে আপনার প্যাকেজ একটিভ করে দেয়া হবে।

              যেমন -
            </p>
            <p>
              <ul class="list-unstyled">
                <li>প্যাকেজ: ১ বছর</li>
                <li>TrxID: AFE8DXXXX</li>
                <li>User ID: 12345</li>
              </ul>
            </p>
            <p>
              এই তথ্যগুলো আমাদের ইনবক্স করলেই আপনার প্যাকেজটি তৎক্ষণাৎ একটিভ করে দেয়া হবে ✅।
            </p>
            <p>
              যে কোন সহায়তায় মেসেজ করুন <a target="_blank"
                                           href="https://web.facebook.com/bcsprostutiApp">bcsprostutiApp</a>
            </p>
            <p>
              ইমেইলঃ <a href="mailto:support@codeminersit.com">support@codeminersit.com</a>
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-20" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-20">আগে প্যাকেজ কেনা ছিল তবে এখন দেখাচ্ছে প্যাকেজ চালু নেই, এর সমাধান কি?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              অনেকেই নিয়মিত আইডি কোনটি ভুলে যান 🤔। অনেক পরীক্ষা দেয়ার পরে, প্রশ্ন মার্ক করে রাখার পরেও ডাটা খুঁজে পান না
              বা প্যাকেজ এক্টিভ করার পরেও প্যাকেজ একটিভ নাই দেখায়। এর মানে আপনি অন্য কোন আইডি দিয়ে লগইন করেছেন। যে আইডি
              দিয়ে নিয়মিত পরীক্ষা দেন বা প্যাকেজ একটিভ করেছিলেন সেই আইডি দিয়েই লগইন করতে হবে। অ্যাপে প্রবেশ করে নিচে
              প্রোফাইলে গিয়ে দেখবেন, একদম উপরে ডান পাশের কর্নার এ প্রোফাইলের মত আইকন আছে, সেখানে ক্লিক 👆 করলেই আপনার
              সম্ভাব্য সকল আইডি দেখতে পাবেন। এতে আপনার কোন নম্বর/ফেসবুক আইডি/মেইল একাউন্ট দিয়ে আগে প্যাকেজ চালু
              করেছিলেন সেটি সহজেই বুঝতে পারবেন। এরপরে বর্তমান অ্যাকাউন্ট থেকে সাইন আউট করে, যে অ্যাকাউন্ট এ প্যাকেজ চালু
              আছে,সেই অ্যাকাউন্ট এ সাইন ইন করলেই আগের সবকিছুই ফিরে পাবেন 🤟।
            </p>
            <p>
              যে কোন সহায়তায় মেসেজ করুন <a target="_blank" href="https://web.facebook.com/bcsprostutiApp">bcsprostutiApp</a>
            </p>
            <p>
              ইমেইলঃ <a href="mailto:support@codeminersit.com">support@codeminersit.com</a>
            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-21" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-21">আমার প্যাকেজ এক্টিভ করা আছে, আমি আবার প্যাকেজ কিনলে কি হবে?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনার প্যাকেজ একটিভ থাকলে আপনি যদি আবার প্যাকেজ চালু করেন তাহলে আগের প্যাকেজটির সাথে এখনকার প্যাকেজটি যোগ➕
              হয়ে যাবে। মানে আগের প্যাকেজ তার মেয়াদমত শেষ হবে, এরপর আপনার এখন কেনা প্যাকেজ এর মেয়াদ শুরু হবে। ধরা যাক
              আপনার ৬ মাসের প্যাকেজের পঞ্চম মাস চলছে। আপনি ডিসকাউন্ট থাকায় আবার ৬ মাসের জন্য প্যাকেজ কিনলেন। এখন আপনার
              বর্তমান প্যাকেজ শেষ হবার পর নতুন করে ৬ মাসের জন্য আপনার প্যাকেজ অটোমেটিক একটিভ হবে ✅।

            </p>
          </div>
        </div>
      </div>

      <div class="faq-drawer">
        <input class="faq-drawer__trigger" id="faq-drawer-22" type="checkbox"/>
        <label class="faq-drawer__title" for="faq-drawer-22">আমার প্যাকেজ কিনা ছিল, কিন্তু আমরা একাউন্ট এর সব ইনফো ভুলে গেছি কি করব?</label>
        <div class="faq-drawer__content-wrapper">
          <div class="faq-drawer__content">
            <p>
              আপনি বিসিএস প্রস্তুতি অ্যাপ টি ইন্সটল করে আপনার ফেসবুক আইডি/গুগল অ্যাকাউন্ট দিয়ে সাইন ইন করুন
              (ফেসবুক/গুগোল আইকন এ ক্লিক করলেই হয়ে যাবে)। এরপরে অ্যাপে প্রবেশ করে নিচে প্রোফাইলে গিয়ে দেখবেন, একদম
              উপরে ডান পাশের কর্নার এ প্রোফাইলের মত আইকন আছে, সেখানে ক্লিক করলেই আপনার সম্ভাব্য সকল আইডি দেখতে পারবেন।
              এতে করে আপনার কোন নম্বর/ফেসবুক আইডি/মেইল একাউন্ট দিয়ে আগে প্যাকেজ চালু করেছিলেন, সেটি সহজেই বুঝতে পারবেন।
              এরপর বর্তমান অ্যাকাউন্ট থেকে সাইন আউট করে, যে অ্যাকাউন্ট এ প্যাকেজ চালু আছে,সেই অ্যাকাউন্ট এ সাইন ইন করলেই
              আগের সবকিছুই ফিরে পাবেন। আর আপনি যদি পাসওয়ার্ড চেঞ্জ করতে চান,তাহলে রিসেট/forgot পাসওয়ার্ড এ গিয়ে
              পাসওয়ার্ড রিসেট করে নিতে পারবেন।
            </p>
            <p>
              যে কোন সহায়তায় মেসেজ করুন <a target="_blank" href="https://web.facebook.com/bcsprostutiApp">bcsprostutiApp</a>
            </p>
            <p>
              ইমেইলঃ <a href="mailto:support@codeminersit.com">support@codeminersit.com</a>
            </p>
          </div>
        </div>
      </div>

    </div>

    <div style="padding-top: 40px">
    </div>
    <div ref="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
//import store from '@/store/index.js';
//import { removeItem, setStore } from "@/config/utils";
// import { StaticTags } from "@/static";
// import { useRouter } from "vue-router";
// import Swal from 'sweetalert2/dist/sweetalert2';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import {onMounted,} from "vue";
import SubMenu from "@/components/SubMenu.vue";
//import httpClient from "@/config/httpClient";

export default {
  components: {
    SubMenu,
    Footer,
    Header,
  },
  setup() {
    // const router = useRouter();
    // const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    // const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
    // const userDetails = ref(null)
    onMounted(() => {


    })


    return {}
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

.faq-drawer {
  margin-bottom: 30px;
}

.faq-drawer__content-wrapper {
  font-size: 1em;
  line-height: 1.4em;
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}

.faq-drawer__title {
  border-top: #00000012 1px solid;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  padding: 30px 0 0 0;
  position: relative;
  margin-bottom: 0;
  transition: all 0.25s ease-out;
}

.faq-drawer__title::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: " ";
  display: inline-block;
  float: right;
  height: 10px;
  left: 2px;
  position: relative;
  right: 20px;
  top: 2px;
  transform: rotate(135deg);
  transition: 0.35s ease-in-out;
  vertical-align: top;
  width: 10px;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover {
  color: #4E4B52;
}

.faq-drawer__trigger:checked
+ .faq-drawer__title
+ .faq-drawer__content-wrapper {
  max-height: fit-content;
  padding-top: 20px;
}

.faq-drawer__trigger:checked + .faq-drawer__title::after {
  transform: rotate(-45deg);
  transition: 0.25s ease-in-out;
}

.faq-drawer__trigger {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 80px;
  }
}
</style>
