<template>
    <transition name="modal-animation">
        <div v-show="modalActive" class="modal">
            <transition name="modal-animation-inner">
                <div v-show="modalActive" class="modal-inner">

                    <!-- Modal Content -->
                    <slot />
                    <div class="modal-content">
                        <div>
                            <h2>{{title}}</h2>
                            <div v-if="isExamRunning && !result">
                                <p>এখানে শুধু ফলাফল দেয়া হল।  সম্পূর্ণ উত্তরপত্র এবং মেধাতালিকা - 5.00 AM এ প্রকাশিত হবে। কোন সমস্যা/জিজ্ঞসা থাকলে আমাদের মেসেন্জারে অবিহিত করুন। </p>
                            </div>
                            <div v-if="!isExamRunning && !result && exam" class="text-center">
                                <table class="table table-borderless table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">পরীক্ষার্থী</th>
                                        <th scope="col">উত্তীর্ণ</th>
                                        <th scope="col">পজিশন</th>
                                        <th scope="col">কাট মার্ক</th>
                                        <th scope="col">সর্বোচ্চ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{ exam.participants }}</td>
                                        <td>{{ exam.passed }}</td>
                                        <td>{{ exam.position }}</td>
                                        <td>{{ exam.cut_mark }}</td>
                                        <td>{{ exam.best }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table class="table table-borderless table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">ফলাফল</th>
                                        <th scope="col">মোট</th>
                                        <th scope="col" style="color: green">সঠিক</th>
                                        <th scope="col" style="color: red">ভুল</th>
                                        <th scope="col" style="color: blue">মার্কস</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="result">
                                        <td>#</td>
                                        <td>{{ result.total }}</td>
                                        <td>{{ result.correct }}</td>
                                        <td>{{ result.wrong }}</td>
                                        <td>{{ result.mark }}</td>
                                    </tr>

                                    <tr v-else >
                                      <td>#</td>
                                      <td>{{ exam.result.total }}</td>
                                      <td>{{ exam.result.correct }}</td>
                                      <td>{{ exam.result.wrong }}</td>
                                      <td>{{ exam.result.mark }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                        <div style="margin: 10px">
                            <button type="button" style="width: 100%;" class="list-group-item btn btn-outline-danger" @click="close">বাতিল করুন</button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    props: ["modalActive", "exam","isExamRunning","title", "result"],
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        };
        const start = () => {
            emit("start")
        }
        return { close, start };
    },
};
</script>

<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);

  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 80%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #dee2e6;
    padding: 64px 16px;

    i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }

    .modal-content {
      background-color: #ffffff;
      padding: 10px;
      border: none;

      h1,
      p {
        margin-bottom: 16px;
      }

      h1 {
        font-size: 32px;
      }

      p {
        font-size: 18px;
      }


    }

    //.button-layout {
    //    display: flex;
    //    flex-direction: row;
    //    justify-content: space-evenly;
    //  }
    //button {
    //  padding: 20px 30px;
    //  border: none;
    //  font-size: 16px;
    //  background-color: crimson;
    //  color: #fff;
    //  cursor: pointer;
    //}
  }
}
</style>