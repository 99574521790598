<template>
  <carousel v-bind="settings" :breakpoints="breakpoints">
    <slide
        v-for="(item, index) in importantCourseTypes"
        :key="index"
        @click="handleClick(item)"
        class="slide-item"
    >
      <div v-if="!item.image">
        <div class="card  course-card cards text-left">
          <div class="card-img-top text-container item-container" :style="{ background: item.color }">
            <div class="item-title ">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card course-card cards text-left">
          <img
              class="card-img-top item-container item-image"
              :src="item.image"
              :alt="item.title"
              style="cursor: pointer"
          >
        </div>
      </div>
    </slide>
    <template #addons>
      <Pagination />
    </template>
  </carousel>
</template>
  
<script>

import httpClient from "@/config/httpClient";
import { defineComponent, ref, onMounted } from "vue";
import {StaticTags, URLs} from "@/static";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2/dist/sweetalert2';
import store from '@/store/index.js';
import {Pagination, Carousel, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  components: {Slide, Carousel, Pagination},



  setup() {
    const importantCourseTypes = ref([])
    const router = useRouter();
    const totalLabel = ref("All Course Types")
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)

    onMounted(() => {
      getCourseTypes()
    });

    const handleClick = (item) => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_NORMAL_COURSE)
        router.push({
          name: URLs.ROUTE_COURSE_EXAM_LIST_NAME,
          params: {
            exam_type: item.id,

          },
        })

    };
    const handleAllButtonClick = () => {
    
      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_NORMAL_COURSE)
        router.push({
          name: URLs.ROUTE_COURSE_TYPES_ALL_NAME,
        })

    };
    const getCourseTypes = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + "/exam-types";
      const config = {
        params: {
          creator_id: -1,  // -1 for important courses documented in the api
        },
      };
      if (isLoggedIn) {
        await httpClient
            .get(url, config)
            .then((res) => {
              if (res.success) {
                importantCourseTypes.value = res.exam_types;
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }

            })
      }
      }


    return {
      handleClick,
      importantCourseTypes,
      totalLabel,
      handleAllButtonClick,
      isLoggedIn,

    };
  },
  data: () => ({
    settings: {
      itemsToShow: 2.5,
      snapAlign: 'start',
      draggable: true,
    },
    breakpoints: {
      768: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
      769: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      1024: {
        itemsToShow: 6,
        snapAlign: 'start',
      },
    },
  }),
});
</script>
  
<style scoped>

.item-container {
  height: 320px;
  width: 217px;
  overflow: hidden; /* if you want to hide the overflow, or you could also use 'scroll' or 'auto' */
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.item-image {
  object-fit: cover;
}

.item-title {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  color: white;
  font-size: 23px;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
}

.slide-item {
  margin: 0 10px;
}


@media (max-width: 768px){
  .card-img-top {
    height: 225px;
    width: 150px;
  }

  .item-title {
    font-size: 18px;
    margin-top: 15px;
  }
}
.scroll-view {
  margin-bottom: 1rem;
}

.scroll-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-container {
  display: inline-block;
}

.scroll-item {
  display: inline-block;
  margin-right: 0.5rem;
}

.image-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.image-button img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 5%;
}

.total-button {
  margin-top: 0.5rem;
}

@media (min-width: 992px) {
  .col-course {
    flex: 0 0 16.666667%;
    max-width: 20%;
  }
}

@media (max-width: 768px) {
  .col-course {
    flex: 0 0 16.666667%;
    max-width: 33%;
  }
}

</style>
  