<template>
  <div class="">
    <div class="footer-section-container">
      <div class="footer-start">
        <div class="footer-logo">
          <img class="bcs-prostuti-logo" src="@/assets/logo2.svg"/>
        </div>
        <div class="footer-social-icon">
          <a target="_blank" href="https://m.facebook.com/groups/admissionprostutiapp">
            <svg width="26" height="26" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink"
                 viewBox="0 0 208.87 208.87" xml:space="preserve">
<g>
	<g>
		<g>
			<path style="fill:#75808A;" d="M142.859,116.944c2.423,1.032,4.839,2.094,7.286,3.049c1.175,0.465,1.617,1.235,1.862,2.452
				c0.549,2.781,0.107,5.448-0.549,8.127c-0.489,2.035-1.396,2.763-3.461,3.097c-0.609,0.101-1.223,0.459-1.724,0.847
				c-0.453,0.352-0.865,0.758-1.283,1.158c4.988,2.124,10.15,4.487,15.299,7.13c2.47,1.271,4.648,2.858,6.48,4.744l1.689,1.748
				v8.372c13.473,0,26.946,0,40.414,0c-1.14-7.638-5.794-12.166-12.829-14.768c-4.583-1.689-9.076-3.646-13.533-5.663
				c-1.271-0.591-2.214-1.856-3.353-2.727c-0.501-0.382-1.122-0.746-1.712-0.853c-2.076-0.328-2.983-1.062-3.473-3.103
				c-0.638-2.673-1.11-5.346-0.549-8.121c0.245-1.217,0.686-1.987,1.862-2.452c2.458-0.949,4.857-2.017,7.286-3.049
				c1.82-0.77,1.921-1.396,1.271-3.276c-2.088-5.901-3.634-11.916-4.135-18.229c-0.328-3.986-0.221-8.061-1.175-11.976h-0.149
				c-0.084-0.364-0.125-0.728-0.221-1.086c-1.76-6.904-9.159-10.657-15.383-11.057c-0.06-0.006-0.107-0.018-0.149-0.018
				c-0.477-0.03-0.931,0.042-1.396,0.072c-2.619,0.364-4.768,2.023-7.262,2.751c-3.24,0.955-5.233,3.938-6.444,7.148
				c-1.712,4.565-1.396,9.434-1.784,14.159c-0.501,6.319-2.053,12.322-4.135,18.223C140.931,115.535,141.051,116.18,142.859,116.944
				z"/>
      <path style="fill:#75808A;" d="M43.17,149.368l1.897-1.784c1.724-1.617,3.783-3.049,6.486-4.487l1.897-1.026
				c2.691-1.45,5.37-2.9,8.139-4.219c1.456-0.692,2.972-1.307,4.463-1.939c-0.316-0.871-0.627-1.742-0.895-2.625
				c-0.352-1.122-0.573-2.25-2.029-2.554c-0.364-0.078-0.811-0.698-0.871-1.122c-0.292-2.166-0.513-4.35-0.662-6.54
				c-0.036-0.453,0.203-1.062,0.525-1.408c1.903-2.13,2.966-4.6,3.395-7.435c0.221-1.408,1.02-2.739,1.426-4.123
				c0.549-1.874,0.996-3.783,1.426-5.686c0.155-0.686,0.066-1.426,0.173-2.13c0.155-1.026,0.125-1.82-1.104-2.214
				c-0.346-0.113-0.621-0.919-0.627-1.42c-0.066-3.043-0.024-6.086-0.036-9.129c-0.024-1.862,0-3.747-0.131-5.603
				c-0.269-3.574-2.363-6.086-4.988-8.252c-3.843-3.156-8.306-6.14-13.145-5.752c-0.119,0.006-0.215,0.036-0.328,0.054
				c-0.113-0.018-0.203-0.048-0.328-0.054c-4.845-0.388-9.314,2.596-13.139,5.752c-2.625,2.166-4.72,4.678-4.988,8.252
				c-0.131,1.862-0.125,3.741-0.131,5.603c-0.012,3.049,0.024,6.092-0.036,9.129c-0.012,0.501-0.292,1.313-0.632,1.42
				c-1.229,0.394-1.259,1.187-1.098,2.214c0.113,0.704,0.018,1.444,0.173,2.13c0.424,1.903,0.871,3.807,1.42,5.686
				c0.406,1.384,1.199,2.715,1.426,4.123c0.436,2.834,1.486,5.305,3.401,7.435c0.316,0.364,0.549,0.955,0.519,1.408
				c-0.149,2.19-0.37,4.362-0.668,6.54c-0.06,0.418-0.507,1.05-0.871,1.122c-1.45,0.304-1.677,1.426-2.023,2.554
				c-0.453,1.492-0.943,2.983-1.522,4.427c-0.179,0.436-0.627,0.907-1.074,1.116c-2.578,1.122-5.209,2.148-7.805,3.246
				c-2.763,1.152-5.555,2.267-8.246,3.556c-2.798,1.331-5.49,2.834-8.198,4.29c-1.611,0.859-3.109,1.808-4.362,2.983v4.779
				c14.386,0,28.76,0,43.135,0v-8.312h0.036V149.368z"/>
		</g>
    <path style="fill:#75808A;" d="M157.549,148.144c-9.487-4.857-19.297-8.891-29.226-12.703c-0.746-0.286-1.629-0.847-1.933-1.504
			c-0.806-1.79-1.366-3.682-1.945-5.567c-0.364-1.199-0.674-2.339-2.005-2.906c-0.352-0.149-0.662-0.853-0.662-1.295
			c0.072-4.356-0.931-8.932,2.297-12.739c0.107-0.125,0.125-0.292,0.209-0.424c1.736-3.819,2.053-8.175,4.487-11.731
			c0.06-0.084,0.072-0.197,0.084-0.304c0.28-2.709,0.585-5.412,0.806-8.121c0.024-0.34-0.269-0.931-0.561-1.038
			c-1.343-0.477-1.271-1.551-1.259-2.631c0-5.293,0-10.585-0.012-15.878c0-3.079-0.907-5.818-3.228-7.906
			c-2.643-2.375-5.37-4.678-8.085-6.981c-1.319-1.116-1.42-1.963-0.197-3.192c0.549-0.537,1.247-0.919,1.862-1.372
			c-0.125-0.209-0.269-0.412-0.394-0.621c-0.829,0-1.665-0.113-2.458,0.018c-3.007,0.483-6.038,0.907-8.986,1.611
			c-5.639,1.331-11.122,3.097-15.711,6.868c-3.127,2.59-5.621,5.597-5.949,9.845c-0.149,2.232-0.131,4.469-0.149,6.701
			c-0.018,3.634,0.03,7.28-0.048,10.907c-0.012,0.597-0.358,1.563-0.758,1.695c-1.468,0.477-1.51,1.414-1.319,2.643
			c0.137,0.841,0.036,1.724,0.215,2.548c0.507,2.273,1.05,4.547,1.701,6.784c0.489,1.665,1.438,3.24,1.701,4.935
			c0.525,3.383,1.778,6.337,4.069,8.885c0.37,0.418,0.662,1.128,0.621,1.689c-0.179,2.608-0.442,5.209-0.8,7.799
			c-0.078,0.507-0.609,1.259-1.044,1.354c-1.73,0.37-1.999,1.695-2.405,3.043c-0.549,1.784-1.122,3.562-1.826,5.287
			c-0.209,0.537-0.752,1.104-1.277,1.331c-3.085,1.349-6.217,2.584-9.32,3.872c-3.306,1.384-6.629,2.715-9.845,4.248
			c-3.33,1.593-6.552,3.371-9.798,5.12c-1.909,1.014-3.705,2.148-5.203,3.568v5.698c37.752,0,75.505,0,113.263,0v-5.937
			C161.07,150.304,159.441,149.111,157.549,148.144z"/>
	</g>
</g>
</svg>
          </a>
          <a target="_blank" href="https://www.facebook.com/admissionprostuti">
            <svg
                class="facebook-1"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_998_3332)">
                <path
                    d="M25.5938 13C25.5938 6.04297 19.957 0.40625 13 0.40625C6.04297 0.40625 0.40625 6.04297 0.40625 13C0.40625 19.2857 5.0116 24.4959 11.0322 25.4414V16.6405H7.83301V13H11.0322V10.2253C11.0322 7.06926 12.9111 5.32594 15.7889 5.32594C17.1671 5.32594 18.6083 5.57172 18.6083 5.57172V8.66938H17.0198C15.4558 8.66938 14.9678 9.64031 14.9678 10.6361V13H18.4605L17.9019 16.6405H14.9678V25.4414C20.9884 24.4959 25.5938 19.2857 25.5938 13Z"
                    fill="#75808A"
                />
              </g>
              <defs>
                <clipPath id="clip0_998_3332">
                  <rect width="26" height="26" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a target="_blank" href="#">
            <svg
                class="linkedin-square-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_998_3335)">
                <path
                    d="M3.70312 20.0937H7.3125V9.25H3.70312V20.0937ZM7.54688 5.90625C7.53646 5.36458 7.34896 4.91667 6.98437 4.5625C6.61979 4.20833 6.13542 4.03125 5.53125 4.03125C4.92708 4.03125 4.4349 4.20833 4.05469 4.5625C3.67448 4.91667 3.48437 5.36458 3.48437 5.90625C3.48437 6.4375 3.66927 6.88281 4.03906 7.24218C4.40885 7.60156 4.89062 7.78125 5.48437 7.78125H5.5C6.11458 7.78125 6.60937 7.60156 6.98437 7.24218C7.35938 6.88281 7.54688 6.4375 7.54688 5.90625ZM16.6875 20.0937H20.2969V13.875C20.2969 12.2708 19.9167 11.0573 19.1562 10.2344C18.3958 9.41146 17.3906 9 16.1406 9C14.724 9 13.6354 9.60938 12.875 10.8281H12.9062V9.25H9.29688C9.32813 9.9375 9.32813 13.5521 9.29688 20.0937H12.9062V14.0312C12.9062 13.6354 12.9427 13.3437 13.0156 13.1562C13.1719 12.7917 13.4062 12.4818 13.7187 12.2266C14.0312 11.9714 14.4167 11.8437 14.875 11.8437C16.0833 11.8437 16.6875 12.6615 16.6875 14.2969V20.0937ZM24 4.5V19.5C24 20.7396 23.5599 21.7995 22.6797 22.6797C21.7995 23.5599 20.7396 24 19.5 24H4.5C3.26042 24 2.20052 23.5599 1.32031 22.6797C0.440104 21.7995 0 20.7396 0 19.5V4.5C0 3.26041 0.440104 2.20052 1.32031 1.32031C2.20052 0.440103 3.26042 0 4.5 0H19.5C20.7396 0 21.7995 0.440103 22.6797 1.32031C23.5599 2.20052 24 3.26041 24 4.5Z"
                    fill="#75808A"
                />
              </g>
              <defs>
                <clipPath id="clip0_998_3335">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>

        </div>
      </div>
      <div class="footer-widgets" style="padding: 0px">
        <div class="col-md-12 col-lg-12">
          <img
              src="@/assets/images/sslcommerze.png"
              height="100%"
              width="100%"
              alt=""
          />
        </div>
      </div>
      <div class="footer-middle"></div>
      <div class="footer-end">
        <div class="footer-copyright">
          স্বত্ব © ২০২৩ বিসিএস প্রস্তুতি কর্তৃক সর্বস্বত্ব সংরক্ষিত
        </div>
        <div class="footer-menubar">
          <a class="about-us" target="_blank" href="/about-us">
            আমাদের সম্পর্কে
          </a>
          <a class="privacy-policy" target="_blank" href=" https://admissionprostuti.com/privacy-policy.html">
            প্রাইভেসি পলিসি
          </a>
          <a class="terms-conditions" target="_blank" href=" https://admissionprostuti.com/terms-condition.html">
            টার্মস এন্ড কন্ডিশন
          </a>
          <a class="cookie-statement" target="_blank" href=" https://admissionprostuti.com/refund-policy.html">
            রিফান্ড পলিসি
          </a>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.about-us {
  color: #12141d;
  font: 400 16px/26px "Noto Sans Bengali", sans-serif;
  opacity: 0.699999988079071;
  position: relative;
}

.privacy-policy {
  color: #12141d;
  font: 400 16px/26px "Noto Sans Bengali", sans-serif;
  opacity: 0.699999988079071;
  position: relative;
}

.terms-conditions {
  color: #12141d;
  font: 400 16px/26px "Noto Sans Bengali", sans-serif;
  opacity: 0.699999988079071;
  position: relative;
}

.cookie-statement {
  color: #12141d;
  font: 400 16px/26px "Noto Sans Bengali", sans-serif;
  opacity: 0.699999988079071;
  position: relative;
}

.footer-menubar {
  display: flex;
  flex-direction: row;
  gap: 13px;
}

.footer-copyright {
  color: #12141d;
  text-align: left;
  font: 400 16px/26px "Noto Sans Bengali", sans-serif;
  opacity: 0.699999988079071;
  position: relative;
  width: 360px;
  height: 29.92px;
}

.linkedin-square-2 {
  position: relative;
  overflow: visible;
}

.facebook-1 {
  position: relative;
  overflow: visible;
}

.twitter-2 {
  position: relative;
  overflow: visible;
}

.footer-logo {
  text-align: start;
}

.footer-end {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.footer-middle {
  background: #ebebeb;
  height: 2px;
  position: relative;
}

.footer-start {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}

.footer-section-container {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.bcs-prostuti-logo {
  width: 88px;
  height: 88px;
  position: relative;
}

.footer-social-icon {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 22px 22px 22px;
  grid-gap: 26px;
  justify-content: flex-end;
}

.footer-widgets {
  padding: 60px 0;
  color: #080809;
}
.footer-widgets img {
  width: 100%;
  height: 100%;
}


@media (min-width: 768px) {
  .footer-end {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .footer-menubar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: right;
  }

}
</style>
  