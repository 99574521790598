<template>
  <Header />
  <div>
    <div>
      <p @click="getLiveTypeResult">Live Exam</p>
      <p @click="getOtherTypeResult">Others</p>
      <div class="container-fluid">

        <div class="col-md-2 col-sm-2 col-2 d-flex align-center">
          <!-- <img src='../assets/images/right-arrow.png' height="25" width="25"/> -->
        </div>

        <div>
          <OtherResultRow v-for="item in otherTyperResultList" :key="item.id" :item="item" @goToExamPage='goToExamPage'>
          </OtherResultRow>

        </div>
        <paginate v-model="curPage" :page-count="20" :page-range="3" :margin-pages="2" :click-handler="clickCallback"
          :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
        </paginate>
        <div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import OtherResultRow from "@/components/OtherResultRow.vue";
import { useRouter } from "vue-router";
import store from '@/store/index.js';
import { StaticTags } from "@/static";
import Paginate from "vuejs-paginate-next";
import httpClient from "@/config/httpClient";
import { setExamInfo, setExamParams } from "@/config/utils";
import Header from "@/components/NewHeaderView.vue";
export default defineComponent({
  components: {Header, OtherResultRow, Paginate },

  setup() {
    const router = useRouter();

    let flowType = store.state.flowType
    var resultType = ref(StaticTags.TYPE_LIVE_EXAM_RESULT)


    var pages = ref([])

    var curPage = ref(1)

    var liveTypeResultList = ref([])
    var otherTyperResultList = ref([])
    onMounted(() => {
      if (!flowType) {
        router.push({
          name: StaticTags.ROUTE_OOPS_PAGE_NAME
        })
      }
      else {
        getLiveTypeResult()
      }


    });

    const getLiveTypeResult = async () => {
      resultType.value = StaticTags.TYPE_LIVE_EXAM_RESULT
      if (liveTypeResultList.value.length == 0) {
        const url = process.env.VUE_APP_API_BASE_URL + "/results";

        await httpClient
          .get(url)
          .then((res) => {

            liveTypeResultList.value = res.results;
          })

      }

    }

    const getOtherTypeResult = async () => {
      resultType.value = StaticTags.TYPE_OTHER_EXAM_RESULT
      if (otherTyperResultList.value.length == 0) {
        const url = process.env.VUE_APP_API_BASE_URL + "/results";

        await httpClient
          .get(url)
          .then((res) => {

            otherTyperResultList.value = res.results;
          })

      }

    }

    const goToExamPage = (item) => {
      setExamParams({
        examId: item.id
      })
      setExamInfo({
        id: item.id,
        title: item.topic
      })
      router.push({
        name: StaticTags.ROUTE_RESULT_QUESTION_NAME
      })
    }

    return {
      pages,
      getOtherTypeResult,
      getLiveTypeResult,
      otherTyperResultList,
      goToExamPage,
      curPage,
    };
  },
});
</script>
  
      
  
     
  
<!-- <style lang="css">
  /* Adopt bootstrap pagination stylesheet. */
  @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

  /* Write your own CSS for pagination */
  .pagination {
  }
  .page-item {
  }
</style> -->