import Vuex from "vuex";
import {ExamRunningState} from './modules/ExamRunningState'
import {loader} from './modules/loader'
import {ExamParams} from "./modules/ExamParams";
import {StaticTags} from '@/static/index.js'
import {getStore, setStore, isCategoryListExpired} from "@/config/utils";
import httpClient from "@/config/httpClient";
import Swal from 'sweetalert2/dist/sweetalert2';

export default new Vuex.Store({
    state: {
        access_token: null,
        categoryList: (getStore(StaticTags.CATEGORY_LIST_WITH_DETAILS)) ? JSON.parse(getStore(StaticTags.CATEGORY_LIST_WITH_DETAILS)) : null,
        flowType: null,
        pageItemList: null,
        examFlowType: null,
        packId: getStore("packId"),
        titleBar: null,

    },
    mutations: {

        setTitleBar(state, titleBar) {
            state.titleBar = titleBar;
            setStore("titleBar", titleBar);
        },

        setAcessToken(state, access_token) {

            state.access_token = access_token;
            setStore(StaticTags.IS_LOGGED_IN, access_token)
        },
        setPackId(state, packId) {
            state.packId = packId;
            setStore("packId", packId);
        },
        setCategoryList(state, categoryList) {
            state.categoryList = categoryList;
            setStore(StaticTags.CATEGORY_LIST_WITH_DETAILS, categoryList)
            var currentTime = new Date()
            setStore(StaticTags.CATEGORY_LIST_SAVED_TIME, currentTime.getTime())
        },

        setFlowType(state, flowType) {
            state.flowType = flowType;
        },

        setExamFlowType(state, examFlowType) {
            state.examFlowType = examFlowType;
        },

        setPageItemList(state, pageItemList) {
            state.pageItemList = pageItemList;
        },
    },
    getters: {
        getToken(state) {
            state.access_token = getStore(StaticTags.IS_LOGGED_IN)
            return state.access_token;
        },
        categoryList: state => {
            return state.categoryList;
        },

        flowType: state => {
            return state.flowType
        },

        titleBar: state => {
            return state.titleBar;
        },

        examFlowType: state => {
            return state.examFlowType
        },

        pageItemList: state => {
            return state.pageItemList
        },

        getPackId(state) {
            return state.packId;
        },
    },
    actions: {
        setAcessToken({commit}, payload) {
            commit("setAcessToken", payload);
        },
        setPageItemList({commit}, payload) {
            commit("setPageItemList", payload);
        },
        setFlowType({commit}, payload) {
            commit("setFlowType", payload);
        },

        setTitleBar({commit}, payload) {
            commit("setTitleBar", payload);
        },

        setExamFlowType({commit}, payload) {
            commit("setExamFlowType", payload);
        },

        async loadCategoryList({commit}) {
            var currentTime = new Date()
            // check category list need to be updated (expired or not save in local)
            if ((!getStore(StaticTags.CATEGORY_LIST_WITH_DETAILS) ||
                    !getStore(StaticTags.CATEGORY_LIST_SAVED_TIME)) ||
                isCategoryListExpired(getStore(StaticTags.CATEGORY_LIST_SAVED_TIME), currentTime)) {
                try {
                    // const config = {
                    //   headers: {
                    //     'api-token': this.state.access_token
                    //   }
                    // }
                    let countList = []
                    const url = process.env.VUE_APP_API_BASE_URL + '/categories'
                    const url2 = process.env.VUE_APP_API_BASE_URL + "/favourite-counts";
                    await httpClient
                        .get(url2)
                        .then((res) => {
                            if (res.success) {
                                countList = res.favouriteCounts
                            } else {
                                Swal.fire({
                                    text: res.message,
                                    title: "Failed",
                                    icon: StaticTags.ALERT_TYPE_ERROR
                                })
                            }
                        })
                    await httpClient
                        .get(url)
                        .then((res) => {
                            let categoryLists = []
                            if (res.success) {
                                if (countList) {
                                    countList.forEach(element => {
                                        const category = res.categories.find((listd) => listd.id === element.id);
                                        category.marked = element.marked;
                                        const subcategories = []
                                        if(element.subcategories){
                                            element.subcategories.forEach(subElement => {
                                                const subcategory = category.subcategories.find((list) => list.id === subElement.id);
                                                subcategory.marked = subElement.marked;
                                                const chapters = []
                                                if(subElement.chapters){
                                                    subElement.chapters.forEach(chapter => {
                                                        const chap = subcategory.chapters.find((list) => list.id === chapter.id);
                                                        chap.marked = chapter.marked;
                                                        chapters.push(chap);
                                                    });
                                                }
                                                subcategory.chapters = chapters;
                                                subcategories.push(subcategory);
                                            });
                                        }
                                        category.subcategories = subcategories;
                                        categoryLists.push(category);
                                    });
                                }
                                commit('setCategoryList', categoryLists)
                            }
                            else {
                                Swal.fire({
                                    text: res.message,
                                    title: "Failed",
                                    icon: StaticTags.ALERT_TYPE_ERROR
                                })
                            }
                        })

                } catch (error) {
                    // console.log(error);
                }
            } else {
                commit('setCategoryList', JSON.parse(getStore(StaticTags.CATEGORY_LIST_WITH_DETAILS)))
            }
        },

        async refreshCategoryList({commit}) {
            try {
                let countList = []
                const url = process.env.VUE_APP_API_BASE_URL + '/categories'
                const url2 = process.env.VUE_APP_API_BASE_URL + "/favourite-counts";
                await httpClient
                    .get(url2)
                    .then((res) => {
                        if (res.success) {
                            countList = res.favouriteCounts
                        } else {
                            Swal.fire({
                                text: res.message,
                                title: "Failed",
                                icon: StaticTags.ALERT_TYPE_ERROR
                            })
                        }
                    })
                await httpClient
                    .get(url)
                    .then((res) => {
                        const categoryLists = []
                        if (res)
                            if (countList) {
                                countList.forEach(element => {
                                    const category = res.categories.find((listd) => listd.id === element.id);
                                    category.marked = element.marked;
                                    const subcategories = []
                                    if(element.subcategories){
                                        element.subcategories.forEach(subElement => {
                                            const subcategory = category.subcategories.find((list) => list.id === subElement.id);
                                            subcategory.marked = subElement.marked;
                                            const chapters = []
                                            if(subElement.chapters){
                                                subElement.chapters.forEach(chapter => {
                                                    const chap = subcategory.chapters.find((list) => list.id === chapter.id);
                                                    chap.marked = chapter.marked;
                                                    chapters.push(chap);
                                                });
                                            }
                                            subcategory.chapters = chapters;
                                            subcategories.push(subcategory);
                                        });
                                    }
                                    category.subcategories = subcategories;
                                    categoryLists.push(category);
                                });
                            }
                        commit('setCategoryList', categoryLists)
                    })

            } catch (error) {
                // console.log(error);
            }


        }

    },
    modules: {
        ExamRunningState,
        loader,
        ExamParams,
    }
})