<template>
    <div class="container-fluid container-fluid-responsive">
        <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="searchQuery" placeholder="Enter your search query">
            <span v-if="searchQuery" class="clear-icon" @click="clearSearch">
                <i class="bi bi-x-circle-fill"></i>
            </span>
            <br>
            <br>
            <button class="btn btn-primary" @click="getSearchList">Search</button>

        </div>

        <div v-if="showRecentSearch && savedSearchList.length>0">
            <h4>Recent Search</h4>
            <div v-if="savedSearchList.length > 0">
                <div v-for="(item, index) in savedSearchList" :key="index" @click="searchByTheSelectedItem(index)">
                   <!-- design as like the mobile -->
                   <p>{{item}}</p>
                   <p @click="deleteFromSearchList(index)">delete</p>
                </div>
            </div>
        </div>

        <div v-if="!showRecentSearch" class="h-100 align-items-center justify-content-center">
            <div class="" v-if="questions.length > 0">
                <SearchQuestionView v-for="(q, index) in questions" :questionIndex="index" :key="q.id" :question="q"
                    :isExamMode="isExamMode" :isExerciseMode="isExerciseMode" :viewOnly="viewOnly"
                    :isMenuShowAble="isMenuShowAble" :isResultMode="isResultMode"
                    v-model:questionWithState="questionWithState[index]" />
            </div>
        </div>
    </div>
</template>



<script>
import { defineComponent, onMounted, ref } from "vue";
import SearchQuestionView from "@/components/SearchQuestionView.vue";
import Question from "@/dataModel/QuestionModel.js";
import QuestionViewInformation from "@/dataModel/QuestionViewInformation.js";
//import { mapGetters } from "vuex";
//import store from '@/store/index.js';
import httpClient from "@/config/httpClient";
//import { StaticTags } from '@/static/index.js'
//import Swal from 'sweetalert2/dist/sweetalert2';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { setStore, getStore } from "@/config/utils";


export default defineComponent({
    components: {
        SearchQuestionView,
    },
    models: {
        Question,
    },

    setup() {

        let questions = ref([]);
        let questionWithState = [];
        const isExamMode = ref(false)
        const viewOnly = ref(true)
        const isResultMode = ref(false)
        const isExerciseMode = ref(false)
        const isMenuShowAble = ref(true)
        const saerchType = ref(1) // change type if needed to search other thing, by default it search for questions
        const searchTypeQuestion = 1
        let ansList = []

        const searchQuery = ref('')
        const showRecentSearch = ref(true)
        const savedSearchList = ref([])

        onMounted(() => {
            //console.log(route.path);
            //getSearchList()
            if (getStore("searchList")) {
                let slist = JSON.parse(getStore("searchList"))
                slist.forEach(function(item) {
                    savedSearchList.value.push(item)
                })
            }
        });

        const clearSearch = () => {
            searchQuery.value = ''
            showRecentSearch.value = true
        }

        const deleteFromSearchList = (i) => {
            savedSearchList.value.splice(i, 1)
            setStore("searchList", savedSearchList.value)
 
        }

        const searchByTheSelectedItem = (i) => {
            searchQuery.value = savedSearchList.value[i]
            deleteFromSearchList(i)
            // console.log("in", i);
            // console.log(savedSearchList.value);
            // console.log(searchQuery.value);
            getSearchList()
        }

        const getSearchList = () => {
            if (searchQuery.value) {
                if (saerchType.value == searchTypeQuestion) {
                    setSearchModeOn()
                    getSearchedQuestions()
                    showRecentSearch.value = false
                    savedSearchList.value.unshift(searchQuery.value)
                }

            }

        };

        const setSearchModeOn = () => {
            isResultMode.value = true
            isExamMode.value = false
            viewOnly.value = true
            isMenuShowAble.value = false

        }

        const getSearchedQuestions = async () => {

            const url = process.env.VUE_APP_API_BASE_URL + "/questions/search";

            await httpClient
                .post(url, {
                    "text": searchQuery.value
                }, {
                    params: {
                        "size": 20,
                    }
                })
                .then((res) => {
                    if (res) {
                        questions.value = res.result;
                        setQuestionAndAnswerState(res)
                    }
                })
        }

        const setQuestionAndAnswerState = (res) => {
            questionWithState = []
            ansList = []
            for (const q of res.result) {
                //here need to be changed for multiple option
                let correctOption = 0
                q.options.forEach(function (option, i) {
                    if (option.answer == 1) {
                        correctOption = i + 1
                    }
                });

                var questionState = new QuestionViewInformation()
                    .setQuestion(q)
                    .setGivenAnswer(correctOption)
                    .build();
                questionWithState.push(questionState)
                if (!isExerciseMode.value) {
                    ansList.push({ qid: q.id, correctAns: correctOption, selectedAns: 0 });
                }
            }

        }




        return {
            questions,
            isExamMode,
            questionWithState,
            viewOnly,
            getSearchList,
            clearSearch,
            deleteFromSearchList,
            searchByTheSelectedItem,
            showRecentSearch,

            isMenuShowAble,
            isResultMode,
            isExerciseMode,
            searchQuery,
            savedSearchList,
        };
    },
});
</script>

<style>
.clear-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>